<template>
  <BreadCrumbs
    v-if="breadCrumbs"
    :breadCrumbs="breadCrumbs"
    :showId="showId"
    :id="article.id"
  />
  <GreenifiedCard :grColor="false">
    <template #cardContent>
      <div class="row">
        <ArticleImages
          :galleryImages="article.images"
          :mainImage="article.images[0]?.url"
          :contentUrl="contentUrl"
        />

        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-start justify-content-between mb-3">
            <div>
              <p class="heading-small">
                {{ article.articleName }}
                {{ article.articleNumber }}
              </p>
              <p class="text-medium mt-2">{{ article.brand }}</p>
            </div>
          </div>
          <div class="desktop-wrapper">
            <template v-for="(row, index) in filteredRows" :key="row.label">
              <InformationRow
                :clickEvent="row.clickEvent"
                :rowType="row.rowType"
                :hideBorder="index !== 0"
                :label="row.label && $t(row.label)"
                :text="
                  row.textValues ? $t(row.text, row.textValues()) : row.text
                "
                :accordionRows="row.accordion?.rows && row.accordion.rows"
                :accordionKey="row.accordion?.key && row.accordion.key"
                :accordionLabelText="
                  row.accordion?.label && $t(row.accordion.label)
                "
                :accordionValue="row.accordion?.value && row.accordion.value"
              />
            </template>
          </div>

          <ButtonContainer
            v-if="buttons"
            :buttons="buttons"
            @clicked="(event) => $emit('clicked', event)"
          />
        </div>
      </div>
    </template>
  </GreenifiedCard>
</template>

<script>
import BreadCrumbs from "../BreadCrumbs.vue";
import GreenifiedCard from "../GreenifiedCard.vue";
import ArticleImages from "./ArticleImages.vue";
import ButtonContainer from "./ButtonContainer.vue";
import InformationRow from "./InformationRow.vue";

export default {
  emits: ["edit", "clicked"],
  props: {
    article: { type: Object, default: null },
    articleInformation: { type: Array, default: null },
    contentUrl: { type: String, default: null },
    buttons: { type: Array, default: null },
    breadCrumbs: { type: Object, default: null },
    showId: { type: Boolean, default: false },
  },
  computed: {
    filteredRows() {
      return this.articleInformation.filter((row) => {
        if (row.accordion?.rows?.length || row.text) {
          return row;
        }
      });
    },
  },
  components: {
    GreenifiedCard,
    ArticleImages,
    BreadCrumbs,
    ButtonContainer,
    InformationRow,
  },
};
</script>

<style scoped>
.pencil-box {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 60px;
  height: 48px;
  background-color: #e6e6e6;
  border-radius: 25px;
}

@media screen and (min-width: 990px) {
  .desktop-wrapper {
    max-height: 24rem;
    overflow: auto;
    overflow-x: hidden;
  }
}
</style>
