<template>
  <div class="row" :class="`mt-${topMargin}`">
    <div class="col-12 col-md-10 col-lg-8">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topMargin: { type: Number, default: 4 },
  },
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .row {
    justify-content: center !important;
  }
}
</style>
