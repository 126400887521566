<template>
  <div class="button-wrapper" :class="[size]">
    <button
      class="greenified-button"
      :type="btnType"
      :class="[
        type,
        size,
        disabled && 'disabled',
        noWrap && 'no-wrap',
        longText && 'long-text',
        textSize,
      ]"
      @click="!disabled && $emit('clicked')"
      :disabled="disabled"
      :form="form"
    >
      <slot name="content">
        {{ text }}
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "GreenifiedButton",
  emits: ["clicked"],
  props: {
    btnType: { type: String, default: "button" },
    type: { type: String, default: "normal" },
    size: { type: String, default: "full" },
    text: { type: String, default: "Click here" },
    textSize: { type: String, default: null },
    disabled: { type: [Boolean, String, Number], default: false },
    noWrap: { type: Boolean, default: false },
    form: { type: String, default: null },
    longText: { type: [Boolean, String], default: null },
  },
};
</script>

<style scoped>
.long-text {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.button-wrapper {
  height: 44px;
  width: max-content;
}
.greenified-button {
  padding-left: 24px;
  padding-right: 24px;
  line-height: 28px;
  font-size: 18px;
  height: 40px;
  border-radius: 25px;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  color: black;
}

.normal {
  background-color: white;
  border: 1.5px solid black;
}

.black {
  background-color: black;
  color: white;
}

.white {
  background-color: white;
  color: black;
  border: 1.5px solid white;
}
.green {
  background-color: #82ab72;
  color: white;
}

.full {
  width: 100%;
}

.wider {
  width: 16rem;
}

.wide {
  width: 12rem;
}

.btn-text-medium {
  font-size: 15px;
}

.medium {
  width: 8rem;
}
.big {
  font-size: 16px;
}
.transparent {
  background-color: transparent;
  color: black;
  border: 1.5px solid transparent;
}

.thin {
  width: max-content;
}

.disabled {
  background-color: rgba(128, 128, 128, 0.5) !important;
  color: rgba(0, 0, 0, 0.5);
  cursor: default;
  border: none;
}

@media screen and (min-width: 768px) {
  .greenified-button {
    height: 42px;
  }
}

@media screen and (max-width: 380px) {
  .greenified-button {
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
