<template>
  <div class="" v-if="!openScanner">
    <FormGroup>
      <FormTitle
        :titleClass="'subheading-medium'"
        :title="
          $t(newReg ? 'pageHeaders.createdSave' : 'pageHeaders.editedSave', {
            id: compId,
          })
        "
        :paragraph="
          newReg ? $t('paragraph.createdSave') : $t('paragraph.editedSave')
        "
      >
      </FormTitle>
      <FormRow :noLabel="true">
        <GreenifiedButton
          :text="$t('button.backToProduct')"
          @clicked="
            $router.push({ name: 'Item', params: { id: id, action: 'edit' } })
          "
          size="big"
        />
      </FormRow>
      <FormRow :noLabel="true">
        <GreenifiedButton
          type="black"
          :text="$t('button.createNewProduct')"
          @clicked="openScanner = true"
          size="big"
        />
      </FormRow>
    </FormGroup>
  </div>
  <div>
    <div class="mt-2" v-if="openScanner">
      <FormGroup>
        <FormRow :noLabel="true">
          <div class="subheading-medium">
            {{
              $store.getters.cameraPermission
                ? $t("button.scanNewProduct")
                : $t("pageHeaders.cameraPermission")
            }}
          </div>
        </FormRow>
        <div style="max-height: 500px" class="d-flex justify-content-center">
          <QrcodeStream
            v-if="openScanner"
            @camera-on="SET_CAMERA_PERMISSION(true)"
            @detect="onDecode"
            style="height: max-500px; max-width: 500px"
          ></QrcodeStream>
        </div>
        <FormTitle
          v-if="!$store.getters.cameraPermission"
          :paragraph="$t('label.cameraSettingsIntstructions')"
        >
        </FormTitle>
        <FormRow :noLabel="true">
          <GreenifiedButton
            v-if="this.id"
            type="black"
            :text="$t('button.close')"
            @clicked="openScanner = false"
            size="big"
        /></FormRow>
      </FormGroup>
    </div>
  </div>
</template>
<script>
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import FormGroup from "@/shared/components/FormGroup.vue";
import FormRow from "@/shared/components/FormRow.vue";
import FormTitle from "@/shared/components/FormTitle.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import { mapMutations } from "vuex/dist/vuex.cjs";

export default {
  name: "savedScreen",
  components: {
    QrcodeStream,
    GreenifiedButton,
    FormGroup,
    FormRow,
    FormTitle,
  },

  props: { id: String },
  data() {
    return {
      openScanner: false,
      compId: this.$route.query.comp_id,
      newReg: this.$route.query.new === "true" ? true : false,
    };
  },

  computed: {},
  mounted() {
    if (!this.id) {
      this.openScanner = true;
    }
  },
  methods: {
    ...mapMutations(["SET_CAMERA_PERMISSION"]),
    onDecode(url) {
      let decodedId = url[0].rawValue.split("/").pop().replace("?", "");
      this.$router.push({ name: "Item", params: { id: decodedId } });
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.history-button {
  height: 32px;
  width: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}
.history-row {
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 4rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.history-container {
  border-bottom: 1px solid black;
  overflow-y: auto;
  height: 100%;
}
.history-row:hover {
  background-color: black;
  color: white;
}
.history-row:active {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.inner-container {
  width: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
</style>
