<template>
  <section
    class="modal-overlay"
    :class="[blank && 'blank', center && 'center', bgColor]"
  >
    <div class="modal-slot">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    blank: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    bgColor: { type: String, default: "black" },
  },
  emits: [],
  methods: {},
  beforeUnmount() {
    document.body.classList.remove("modal-open");
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
};
</script>

<style scoped>
.modal-overlay {
  width: 100vw;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 50;
}

.black {
  background-color: rgba(0, 0, 0, 0.25);
}

.white {
  background-color: white;
}

.modal-slot {
  width: 100%;
  max-width: 30rem;
  padding-top: 96px;
}

.blank {
  background-color: white;
  justify-content: flex-start;
  align-items: flex-start !important;
  z-index: 4;
  max-width: 1100px;
}
.center {
  align-items: center;
}

@media screen and (min-width: 512px) {
  .modal-overlay {
    align-items: center;
  }

  .blank {
    left: auto;
  }
}
</style>
