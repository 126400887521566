<template>
  <div class="row">
    <div
      class="col-12 col-md-10 col-lg-8 d-flex align-items-center justify-content-between"
    >
      <div class="col-6">
        <p class="subheading-medium no-wrap">
          {{ text }}
        </p>
      </div>
      <div class="col-5 d-flex justify-content-end">
        <GreenifiedButton
          size="thin"
          :text="$t('button.return')"
          @clicked="$emit('return')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";

export default {
  emits: ["return"],
  props: { text: { type: String, default: null } },
  components: { GreenifiedButton },
};
</script>

<style scoped>
.return-icon-wrapper {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
}

.return-icon {
  transform: rotate(90deg);
}

@media screen and (min-width: 768px) {
  .row {
    justify-content: center !important;
  }
}
</style>
