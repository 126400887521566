<template>
  <div class="d-flex" :class="vertical ? 'flex-column' : 'align-items-center'">
    <div
      class="checkbox-wrapper"
      :class="showError && 'error-checkbox'"
      @click="
        () => {
          if (selected === trueValue) {
            selected = falseValue;
          } else {
            selected = trueValue;
          }
        }
      "
    >
      <input
        type="checkbox"
        :true-value="trueValue"
        :false-value="falseValue"
        v-model="selected"
        @input="$emit('update:modelValue', selected)"
      />
    </div>
    <label
      v-if="label"
      class="subheading-tiny"
      :class="[vertical && 'mb-1', showError && 'error-text']"
      >{{ label }}</label
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: this.modelValue,
    };
  },
  props: {
    modelValue: { type: [String, Number], default: null },
    label: { type: [String, Number], default: null },
    vertical: { type: Boolean, default: false },
    showError: { type: Boolean, default: false },
    trueValue: { type: [String, Number, Boolean], default: true },
    falseValue: { type: [String, Number, Boolean], default: false },
  },
};
</script>

<style scoped>
.checkbox-wrapper {
  display: flex;
  align-items: center;
  min-height: 42px;
  min-width: 42px;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  min-height: 24px;
  min-width: 24px;
  background: url("@/assets/img/square-small.svg") no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.error-checkbox input[type="checkbox"]:before {
  background: url("@/assets/img/square-error.svg") no-repeat !important;
}
input[type="checkbox"]:checked:before {
  background: url("@/assets/img/check-small.svg") no-repeat !important;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
