<template>
  <div @click="$emit('clicked')" class="edit-pencil-container">
    <img src="@/assets/img/edit_pen.svg" alt="edit pen" class="edit-pencil" />
  </div>
</template>

<script>
export default {
  emits: ["clicked"],
};
</script>

<style scoped>
.edit-pencil-container {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-pencil {
  width: 24px;
  height: 24px;
}
</style>
