<template>
  <ul :key="value" class="ul-radio">
    <li
      :style="'margin-left: 0px !important;'"
      v-for="(choice, key) in choices"
      :key="choice"
      class="li-radio"
    >
      <label
        :for="key"
        :key="key"
        :class="[
          showError && 'error',
          value && value === key
            ? 'checkedMulticheck multicheck'
            : 'multicheck',
        ]"
        @click="$emit('click', key)"
      >
        <input
          @click.stop
          style="
            height: 0px;
            width: 0px;
            padding: 0px;
            margin: 0px;
            border: 0px !important;
            position: absolute;
          "
          :required="required"
          type="radio"
          :value="value"
          :checked="value && value === key"
        />
        {{ choice }}
      </label>
    </li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
    <li class="empty"><label></label></li>
  </ul>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    choices: { type: Object, default: () => {} },
    required: { type: Boolean, default: false },
    value: { type: [Number, String], default: null },
    showError: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
ul.ul-radio {
  list-style-type: none;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  margin-bottom: 0px;
  margin-right: -10px;
}

li.li-radio {
  display: inline-block;
  margin: 0 10px;
  flex-grow: 1;
}
li.empty {
  height: 0px;
  margin: 0 10px;
  flex-grow: 1;
  display: inline-block;
  margin-left: 0px !important;
  label {
    min-width: 120px;
    padding: 14px 17px 12px !important;
  }
}
.error {
  border-color: #dc3545 !important;
  color: #dc3545 !important;
}
input[type="radio"] {
  height: 33px;
  width: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
