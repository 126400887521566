import axios from "axios";
import router from "@/item/router";
import store from "../store";
const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": process.env.VUE_APP_LARAVEL_API,
};

export default () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_LARAVEL_API + "/api/supplier",
    withCredentials: false,
    headers: header,
  });

  instance.interceptors.request.use((response) => {
    return response;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch("auth/logout");
        router.push({ name: "login" });
      }
    }
  );

  return instance;
};
