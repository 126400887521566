<template>
  <GreenifiedCard>
    <template #cardContent>
      <div class="d-flex align-items-center justify-content-center h-full">
        <p class="heading-small">404 Page not found</p>
      </div>
    </template>
  </GreenifiedCard>
</template>

<script>
import GreenifiedCard from "../components/GreenifiedCard.vue";
export default {
  components: { GreenifiedCard },
};
</script>

<style scoped></style>
