<template>
  <div
    v-if="row"
    @click="checkAddrow(row)"
    class="col-12 d-flex col-md-6 justify-content-between mb-2 py-1"
    :class="[marked && 'markedRow']"
  >
    <div class="product-image">
      <img
        style="width: auto"
        v-if="row.images?.bild_1"
        :src="content + row.images?.bild_1.hash"
      />
    </div>
    <div class="ms-1 w-100">
      <div class="d-flex justify-content-between">
        <p class="subheading-small ellipsis product-title">
          {{ row.product_name }}
        </p>
        <p class="text-min">
          {{ row.updated_at && timeSince(row.updated_at) }}
        </p>
      </div>
      <p class="text-tiny">{{ row.brand }}</p>
      <p class="text-min    ">{{ row.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryRow",
  emits: ["click"],
  computed: {
    content() {
      return process.env.VUE_APP_CONTENT_API;
    },
  },
  props: {
    row: { type: Object, default: () => {} },
    marked: { type: Boolean, default: false },
  },
  methods: {
    checkAddrow() {
      this.$emit("click", this.row);
    },

    timeSince(inDate) {
      var date = new Date(inDate);
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      date = new Date(date.getTime() + userTimezoneOffset);
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;

      if (interval > 1) {
        return (
          Math.floor(interval) +
          " " +
          this.$t("label.ago", [
            this.$t("label.year", { count: Math.floor(interval) }),
          ])
        );
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return (
          Math.floor(interval) +
          " " +
          this.$t("label.ago", [
            this.$t("label.month", { count: Math.floor(interval) }),
          ])
        );
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return (
          Math.floor(interval) +
          " " +
          this.$t("label.ago", [
            this.$t("label.day", { count: Math.floor(interval) }),
          ])
        );
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return (
          Math.floor(interval) +
          " " +
          this.$t("label.ago", [
            this.$t("label.hour", { count: Math.floor(interval) }),
          ])
        );
      }
      interval = seconds / 60;
      if (interval > 1) {
        return (
          Math.floor(interval) +
          " " +
          this.$t("label.ago", [
            this.$t("label.minute", { count: Math.floor(interval) }),
          ])
        );
      }
      return (
        Math.floor(seconds) +
        " " +
        this.$t("label.ago", [
          this.$t("label.second", { count: Math.floor(interval) }),
        ])
      );
    },
  },
};
</script>

<style scoped>
.product-title {
  max-width: 10rem;
}

.product-image {
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  display: flex;
  background-color: #f5f2eb;
}

.product-image img {
  max-width: 3.5rem;
  max-height: 3.5rem;
  margin: auto;
  -o-object-fit: contain;
  object-fit: contain;
}
.markedRow {
  background-color: black;
  color: white;
}
</style>
