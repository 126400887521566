<template>
  <div style="position: relative; width: 33px; height: 33px; cursor: pointer">
    <div class="checkbox-icon">
      <div v-if="checked === null ? checkedIntern == trueValue : checked"></div>
      <div v-if="checked === null ? checkedIntern == trueValue : checked"></div>
    </div>
    <input
      style="
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0px;
        appearance: none;
        -webkit-appearance: none;
      "
      :true-value="trueValue"
      :false-value="falseValue"
      type="checkbox"
      :required="required"
      v-model="model"
    />
  </div>
</template>

<script>
export default {
  name: "CheckboxStandard",
  props: {
    modelValue: { type: [Array, Boolean] },
    value: { type: [Boolean, Object, Number] },
    required: { type: Boolean, default: false },
    checked: { type: Boolean, default: null },
    trueValue: { type: [String, Number, Boolean], default: true },
    falseValue: { type: [String, Number, Boolean], default: false },
  },
  emits: ["input"],
  data() {
    return {
      checkedIntern: null,
    };
  },
  mounted() {
    this.checkedIntern = this.value ?? this.modelValue;
  },

  computed: {
    model: {
      get: function () {
        return this.checkedIntern;
      },
      set: function (value) {
        this.checkedIntern = value;
        this.$emit("input", {
          target: { value: value === this.trueValue ? "on" : "off" },
        });
      },
    },
  },
};
</script>
<style scoped lang="scss">
.checkbox-icon {
  border: 1.5px solid black;
  width: 33px;
  cursor: pointer;
  height: 33px;
  background-color: white;
  position: relative;
  & div:first-child {
    width: 0;
    height: 0;
    top: 7px;
    left: 5px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19.8px solid black; /* You can change the color here */
    position: relative;
    transform: rotate(180deg);
  }

  /* Optional: Center the V-shape horizontally */
  & div:first-child::before {
    content: "";
    position: absolute;
    top: 1.5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19.8px solid #fff; /* Set the background color of the page here */
  }
  & div:nth-child(2) {
    position: absolute;
    transform: rotate(180deg);
    width: 8px;
    height: 4px;
    top: 8px;
    left: 3px;
    transform: rotate(59deg);

    background-color: white;
  }
}

input[type="checkbox"]:checked:before {
  background: none;
}
input[type="checkbox"]:checked:before,
input[type="radio"]:checked:before {
  background: none !important;
}
input[type="checkbox"]:before,
input[type="radio"]:before {
  background: none !important;
}
</style>
