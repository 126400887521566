<template>
  <div class="text-wrapper" :class="textClass" @click="$emit('clicked')">
    <img src="@/assets/img/add_icon.svg" class="add-icon me-2" v-if="addIcon" />

    <slot name="text">
      <p>{{ text }}</p>
    </slot>
  </div>
</template>

<style scoped>
.text-wrapper {
  min-height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-icon {
  width: 20px;
}
</style>

<script>
export default {
  props: {
    textClass: { type: String, default: "text-small" },
    addIcon: { type: Boolean, default: true },
    text: { type: String, default: null },
  },
  emits: ["clicked"],
};
</script>
