<template>
  <div class="container-fluid navbar-wrapper" id="navbar">
    <div class="row body-padding main-layout">
      <div
        class="col-12 p-0 mobile-navigation align-items-center justify-content-between"
      >
        <img
          class="greenified-logo"
          alt="greenified-logo"
          :src="api_domain + '/api/logo'"
        />

        <HamburgerIcon
          v-model="toggleMenu"
          @update:modelValue="
            (open) => {
              if (open) {
                showSidebar = true;
              } else {
                $refs.sideBar.closeMenu();
              }
            }
          "
        />
      </div>

      <div class="col-sm-12 desktop-navigation align-items-center">
        <div class="col-sm-2">
          <img class="greenified-logo" :src="api_domain + '/api/logo'" />
        </div>

        <div class="col-sm-10">
          <DesktopNavigation :desktop="desktop" :companyLogo="companyLogo" />
        </div>
      </div>

      <SideBar
        v-if="showSidebar"
        :isLoading="isLoading"
        v-model="showSidebar"
        :sideBar="sideBar"
        :companyLogo="companyLogo"
        ref="sideBar"
        @update:modelValue="(active) => (toggleMenu = active)"
      />
    </div>
  </div>

  <slot name="pageContent" />
</template>

<script>
import HamburgerIcon from "../../shared/navbar/HamburgerIcon.vue";
import DesktopNavigation from "./DesktopNavigation.vue";
import SideBar from "./SideBar.vue";

export default {
  props: {
    sideBar: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    desktop: { type: Object, default: null },
    companyLogo: { type: String, default: null },
  },
  data() {
    return {
      toggleMenu: false,
      showSidebar: false,
    };
  },
  components: { HamburgerIcon, SideBar, DesktopNavigation },
  computed: {
    api_domain() {
      return process.env.VUE_APP_LARAVEL_API;
    },
  },

  watch: {
    showSidebar(active) {
      if (active) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/scss/navbar.scss";
</style>
