<template>
  <div class="container-fluid header-wrapper">
    <div class="body-padding main-layout">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    document.getElementById("navbar").style.display = "none";
  },
  beforeUnmount() {
    document.getElementById("navbar").style.display = "flex";
  },
  props: {},
  components: {},
  computed: {},
};
</script>

<style scoped>
.header-wrapper {
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 30;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  border-bottom: 1px solid #f5f2eb;
}
</style>
