<template>
  <HandleViewContent
    @refresh="getAddresses"
    @post="postAddresses"
    :isLoading="isLoading"
    :view="1"
    :inputKey="'building'"
    :rowKey="'id'"
    :errorText="$t('errorMessage.missingAddress')"
    :addText="$t('button.addAddress')"
    :phText="$t('paragraph.addresses')"
    :phTitle="$t('pageHeaders.addressOverview')"
    :tableData="addresses"
  />
</template>

<script>
import HandleViewContent from "../components/HandleViewContent.vue";
import item from "../services/api/item.js";
export default {
  data() {
    return {
      isLoading: true,
      addresses: null,
      projectNumber: this.$store.getters.project_settings.projekt,
    };
  },
  components: {
    HandleViewContent,
  },
  methods: {
    getAddresses() {
      this.isLoading = true;
      this.addresses = null;

      item.getAddresses(this.projectNumber).then((response) => {
        this.addresses = response.data;
        this.isLoading = false;
      });
    },
    postAddresses() {
      let missingAddress = this.addresses.find((address) => !address.building);
      if (!missingAddress) {
        this.isLoading = true;
        this.addresses.forEach((address) => {
          item.updateAddress(
            this.projectNumber,
            {
              building: address.building,
              address: address.address,
            },
            address.id
          );
        });
        this.getAddresses();
      }
    },
  },
  mounted() {
    this.getAddresses();
  },
};
</script>
