<template>
  <div
    class="row sidebar-row"
    @click="
      () => {
        if (subNavigations) {
          toggleSubNavigations = !toggleSubNavigations;
        } else {
          $emit('clicked');
        }
      }
    "
  >
    <div class="col-6 d-flex align-items-center pe-0">
      <slot name="sbrLabel">
        <p class="subheading-large no-wrap">
          {{ label }}
        </p>
      </slot>
    </div>
    <div class="col-6">
      <slot name="sbrAction" />
    </div>
  </div>

  <slot name="subNavigations">
    <AccordionContainer :toggleAccordion="toggleSubNavigations">
      <template #content>
        <div
          class="row sidebar-row"
          v-for="(item, i) in subNavigations"
          :key="i"
          @click="$emit('clicked', item.route)"
        >
          <div class="col-6">
            <p
              class="text-small"
              :class="item.route === currentRoute && 'fw-600'"
            >
              {{ item.label }}
            </p>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <img
              :src="dropArrow"
              class="sub-navicon me-1"
              style="transform: rotate(-90deg)"
            />
          </div>
        </div>
      </template>
    </AccordionContainer>
  </slot>
</template>

<script>
import AccordionContainer from "@/shared/components/AccordionContainer.vue";
import DropArrow from "@/assets/img/drop-arrow.svg";

export default {
  data() {
    return {
      toggleSubNavigations: false,
      dropArrow: DropArrow,
    };
  },
  emits: ["clicked"],
  props: {
    label: { type: String, default: "" },
    subNavigations: { type: Array, default: () => null },
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {},
  created() {
    if (this.subNavigations) {
      let initialAccordionState = this.subNavigations.some(
        (item) => item.route === this.currentRoute
      );
      this.toggleSubNavigations = initialAccordionState;
    }
  },
  components: { AccordionContainer },
};
</script>

<style scoped>
.sub-navicon {
  width: 24px;
}
.sidebar-row {
  align-items: center;
  cursor: pointer;
  height: 56px;
}
</style>
