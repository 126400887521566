<template>
  <div class="row mt-2rem">
    <div class="col-12 col-md-10 col-lg-8 d-flex justify-content-end">
      <div class="me-4" v-if="cancelText">
        <GreenifiedButton
          size="thin"
          type="black"
          :text="cancelText"
          @clicked="$emit('cancel')"
        />
      </div>
      <GreenifiedButton
        v-if="confirmText"
        size="thin"
        type="black"
        :text="confirmText"
        @clicked="$emit('confirm')"
      />
    </div>
  </div>
</template>

<script>
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";

export default {
  props: {
    cancelText: { type: String, default: null },
    confirmText: { type: String, default: null },
  },
  emits: ["confirm", "cancel"],
  components: { GreenifiedButton },
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .row {
    justify-content: center !important;
  }
}
</style>
