<template>
  <div
    class="row info-row me-0 ms-0"
    :class="[hideBorder && 'top-border', !showMore && 'align-items-center']"
  >
    <div
      class="col-4 ps-0 d-flex align-items-center"
      :style="{ height: `${textHeight}px !important` }"
      @click="hideOverflow = !hideOverflow"
    >
      <slot name="label">
        <p
          :class="[
            subheaderLabel ? 'subheading-small' : 'text-small',
            text === null && 'no-wrap',
          ]"
        >
          {{ label }}
        </p>
      </slot>
    </div>
    <div
      class="pe-0 d-flex justify-content-between align-items-center"
      @click="hideOverflow = !hideOverflow"
      ref="textContainer"
      :class="showMore ? 'col-6' : 'col-8'"
    >
      <p
        class="text-small"
        :class="[hideOverflow ? 'hide-overflow' : 'sliding-text']"
      >
        {{ text }}
      </p>

      <RotateArrow v-if="accordionRows" :rotate="hideOverflow" size="half" />
    </div>
    <div
      v-if="showMore"
      @click="hideOverflow = !hideOverflow"
      class="col-2 d-flex align-items-center justify-content-end pe-0"
      :style="{ height: `${textHeight}px !important` }"
    >
      <IconButton
        :img="hideOverflow ? 'add_icon.svg' : 'minus_icon.svg'"
        :width="22"
        :height="22"
        noWrapper
      />
    </div>

    <div class="col-12 ps-0 pe-0" v-if="accordionRows">
      <AccordionContainer :toggleAccordion="hideOverflow">
        <template #content>
          <slot name="accordionContent">
            <template v-for="(row, i) in accordionRows" :key="i">
              <div class="row mt-3" v-if="row[accordionValue]">
                <template v-if="!rowType || rowType === 'link'">
                  <div class="col-4">
                    <p class="text-small">
                      {{
                        accordionLabelText
                          ? row[accordionKey]
                          : $t(row[accordionKey])
                      }}
                      {{ accordionLabelText }}
                    </p>
                  </div>
                  <div class="col-6 ps-3">
                    <p class="text-small" v-if="!rowType">
                      {{ row[accordionValue] }}
                    </p>
                    <a
                      v-else
                      class="text-small link"
                      :href="row[accordionValue]"
                    >
                      {{ row[accordionValue] }}
                    </a>
                  </div>
                </template>
                <template v-if="rowType === 'file'">
                  <div
                    class="col-11 d-flex align-items-center my-1 cursor-pointer"
                    @click="clickEvent(row.hash)"
                  >
                    <img
                      src="../../../assets/img/file_icon.svg"
                      alt="file icon"
                      class="me-2"
                    />
                    <p class="text-small ellipsis">
                      {{
                        accordionLabelText
                          ? row[accordionKey]
                          : $t(row[accordionKey])
                      }}
                      {{ accordionLabelText }}
                    </p>
                  </div>
                </template>
              </div>
            </template>
          </slot>
        </template>
      </AccordionContainer>
    </div>
  </div>
</template>

<script>
import IconButton from "@/shared/components/IconButton.vue";
import RotateArrow from "../RotateArrow.vue";
import AccordionContainer from "../AccordionContainer.vue";

export default {
  components: { IconButton, RotateArrow, AccordionContainer },
  props: {
    label: { type: String, default: null },
    text: { type: String, default: null },
    hideBorder: { type: Boolean, default: true },
    subheaderLabel: { type: Boolean, default: true },
    accordionRows: { type: Array, default: null },
    accordionKey: { type: String, default: null },
    accordionValue: { type: String, default: null },
    accordionLabelText: { type: String, default: null },
    rowType: { type: String, default: null },
    clickEvent: { type: Function, default: () => {} },
  },
  data() {
    return {
      hideOverflow: false,
      textHeight: null,
      showMore: false,
    };
  },

  methods: {
    containerHeight() {
      if (this.$refs.textContainer) {
        this.$nextTick(() => {
          let containerHeight = this.$refs.textContainer.clientHeight;
          this.hideOverflow = containerHeight > 60;
          this.showMore = containerHeight > 60;
        });
      }
    },
  },
  mounted() {
    this.containerHeight();
  },
  updated() {
    if (this.hideOverflow) {
      this.textHeight = this.$refs.textContainer.clientHeight;
    }
  },
};
</script>

<style scoped>
.sliding-text {
  transition: 0.2s;
}

.top-border {
  border-top: 0 !important;
}
.info-row {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hide-overflow {
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.2s;
}
</style>
