<template>
  <div :class="isSticky && 'page-header-wrapper'" class="mb-2">
    <GreenifiedCard :grColor="grColor" :hidePadding="['bottom', hidePadding]">
      <template #cardContent>
        <div class="row" v-if="title">
          <div class="col subheading-medium">
            <slot name="title">{{ title }}</slot>
          </div>
        </div>
        <div class="row mt-4" v-if="text">
          <div class="col">
            <p class="text-tiny">{{ text }}</p>
          </div>
        </div>
        <div class="row" :class="text || title ? 'mt-4' : ''">
          <slot name="headerContent" />
        </div>
        <TableHeader v-if="withTableHeader">
          <template #thContent>
            <slot name="thContent" />
          </template>
        </TableHeader>
      </template>
    </GreenifiedCard>
  </div>
</template>

<script>
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";
import TableHeader from "./TableHeader.vue";

export default {
  components: { TableHeader, GreenifiedCard },

  props: {
    withTableHeader: { type: Boolean, default: false },
    isSticky: { type: Boolean, default: true },
    grColor: { type: Boolean, default: true },
    title: { type: String, default: null },
    text: { type: String, default: null },
    hidePadding: { type: String, default: null },
  },
};
</script>

<style scoped>
.page-header-wrapper {
  position: sticky;
  width: 100%;
  top: 96px;
  left: 0;
  background-color: white;
  z-index: 3;
}
</style>
