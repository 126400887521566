<template>
  <PageHeader>
    <template #headerContent>
      <div class="mb-4">
        <div v-if="scanningDone">
          <FormTitle
            style="margin-bottom: 0px !important"
            :titleClass="'subheading-medium justify-content-center'"
            :title="$t('pageHeaders.scanningDone')"
          />
          <div class="row justify-content-center mt-4">
            <div class="col-6 d-flex justify-content-center">
              <GreenifiedButton
                size="thin"
                :text="$t('button.return')"
                @clicked="$emit('return')"
              />
            </div>
          </div>
        </div>

        <div
          class="camera-wrapper row justify-content-center"
          v-if="!scanningDone"
        >
          <div class="col-12 col-sm-10 col-md-8 col-lg-6">
            <div class="camera-overlay">
              <img
                src="@/assets/img/camera-scan.svg"
                alt="scan icon"
                class="camera-scan"
              />
            </div>
            <QrcodeStream
              v-if="!scanningDone"
              @camera-on="onCameraOn"
              @camera-off="onCameraOff"
              @detect="onDecode"
              style="height: none"
              :track="paintOutline"
              @error="onError"
              :paused="paused"
            ></QrcodeStream>
          </div>
        </div>
      </div>
    </template>
  </PageHeader>
  <div class="product-wrapper">
    <div class="mt-4">
      <form-group :key="productsToScan" noMargin>
        <FormRow topLabel>
          <p class="subheading-tiny mb-0">
            {{ $t("tableHeaders.scanNewProducts", [productsToScan.length]) }}
          </p>
        </FormRow>

        <form-row
          v-for="(newProduct, i) in productsToScan"
          :key="newProduct"
          :noLabel="true"
          :align-center="true"
        >
          <div :ref="'row' + i"></div>
          <InputField
            class=""
            size="small"
            :notEditable="true"
            :flashGreen="newProduct.new"
            :value="`${$t('label.product')} ` + (i + 1)"
            :show-unit="newProduct.comp_id != null"
            :unitText="newProduct.comp_id"
          />
          <IconButton
            :width="24"
            :height="24"
            class="ms-4"
            @click="openNewProductEdit(i)"
            :disabled="newProduct.comp_id === undefined"
            :img="
              newProduct.comp_id
                ? edited(newProduct)
                  ? 'edit_pen.svg'
                  : 'plus.svg'
                : 'plus-disabled.svg'
            "
          />
        </form-row>
      </form-group>
    </div>
  </div>

  <VerificationModal
    :single-button="true"
    :text="
      $t(
        'verificationTexts.wrongProject',
        productBelongsToOtherProjectError.map((elem) => elem.comp_id).join(',')
      )
    "
    :title="$t('verificationTitles.anotherProject')"
    v-if="
      productBelongsToOtherProjectError.length > 0 && !paused && !scanningDone
    "
    @close="productBelongsToOtherProjectError = []"
  />

  <VerificationModal
    :single-button="true"
    :text="
      $t(
        'verificationTexts.scannedProductNotActive',
        produtNotActive.reduce((elem) => elem.comp_id).join(',')
      )
    "
    :title="$t('verificationTitles.inactiveProduct')"
    v-if="produtNotActive.length > 0 && !paused && !scanningDone"
    @close="produtNotActive = null"
  >
  </VerificationModal>
  <template v-if="!paused && !scanningDone">
    <VerificationModal
      v-for="(elem, i) in productIsNotEmptyError"
      :key="elem"
      :single-button="true"
      :text="$t('verificationTexts.scannedProductIsRegistered', elem)"
      :title="$t('verificationTitles.existingProduct')"
      @close="productIsNotEmptyError = null"
      ><template #content>
        <div>
          <GreenifiedButton
            type="black"
            size="medium"
            textSize="btn-text-medium"
            :text="this.$t('button.cancel')"
            @click="productIsNotEmptyError.splice(i, 1)"
            :no-wrap="true"
          >
          </GreenifiedButton>
          <div class="modal-text mt-4">
            {{ $t("paragraph.overwriteExistingData") }}
          </div>
          <GreenifiedButton
            type="black"
            size="medium"
            textSize="btn-text-medium"
            class="mt-4"
            :text="this.$t('button.replaceInfo')"
            @click="
              addScanned(elem.comp_id, elem.id),
                productIsNotEmptyError.splice(i, 1)
            "
            :no-wrap="true"
          >
          </GreenifiedButton>
        </div>
        <div></div>
      </template> </VerificationModal
  ></template>
</template>
<script>
import FormRow from "@/shared/components/FormRow";
import FormGroup from "@/shared/components/FormGroup";
import FormTitle from "@/shared/components/FormTitle";
import IconButton from "@/shared/components/IconButton";
import InputField from "@/shared/components/InputField.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import item_api from "@/item/services/api/item";
import VerificationModal from "@/shared/modals/VerificationModal.vue";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import debounce from "debounce";
import PageHeader from "@/shared/components/PageHeader.vue";

export default {
  name: "itemComponent",
  props: {
    productsToScan: { type: Array, default: () => [{}] },
  },
  data() {
    return {
      ready: false,
      localProductToScan: this.productsToScan,
      productBelongsToOtherProjectError: [],
      produtNotActive: [],
      productIsNotEmptyError: [],
      showScanConfirmation: false,
      paused: false,
    };
  },
  components: {
    FormGroup,
    InputField,
    FormTitle,
    FormRow,
    IconButton,
    QrcodeStream,
    VerificationModal,
    GreenifiedButton,
    PageHeader,
  },
  computed: {
    scanningDone() {
      return this.productsScanned === this.productsToScan.length;
    },
    productsScanned() {
      return this.productsToScan.filter((element) => element.comp_id).length;
    },
  },

  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeUnmount() {
    document.body.classList.remove("modal-open");
  },
  emits: ["update:productsToScan", "openNewProductEdit", "return"],
  methods: {
    onError: console.error,
    onCameraOn() {
      this.ready = true;
      this.showScanConfirmation = false;
    },

    onCameraOff() {
      this.showScanConfirmation = true;
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    scrollToInput(i) {
      this.$refs["row" + i][0].scrollIntoView({
        behaviour: "instant",
      });
    },
    openNewProductEdit(i) {
      this.$emit("openNewProductEdit", i);
    },
    addScanned(comp_id, id) {
      this.scrollToInput(this.productsScanned);

      if (!this.scanningDone)
        this.localProductToScan[this.productsScanned] = {
          id: id,
          comp_id: comp_id,
          productSpecificImages: [],
          damages: null,
          productSpecifics: {},
          new: true,
        };
    },

    edited(product) {
      if (
        product.damages !== null ||
        Object.keys(product.productSpecifics).length !== 0 ||
        product.productSpecificImages.filter((elem) => elem !== null).length > 0
      ) {
        return true;
      }
      return false;
    },

    onDecode: debounce(function (qrCode) {
      this.paused = true;
      if (window.navigator && window.navigator.vibrate) {
        window.navigator.vibrate(500);
      }

      qrCode.forEach((e) => {
        let decodedId = e.rawValue.split("/").pop().replace("?", "");
        item_api
          .getCompId(this.$store.getters.project_settings.projekt, decodedId)
          .then((response) => {
            const found = this.localProductToScan.find(
              (element) =>
                parseInt(element.comp_id) === parseInt(response.data.comp_id)
            );
            if (!found) {
              this.addScanned(response.data.comp_id, decodedId);

              this.$emit("update:productsToScan", this.localProductToScan);
            }
          })
          .catch((e) => {
            if (e.response && e.response.status === 422) {
              if (e.response.data.errorcode === 100) {
                this.productBelongsToOtherProjectError.push({
                  comp_id: e.response.data.comp_id,
                  id: decodedId,
                });
              }
              if (e.response.data.errorcode === 101) {
                this.productIsNotEmptyError.push({
                  comp_id: e.response.data.comp_id,
                  id: decodedId,
                });
              }
              if (e.response.data.errorcode === 102) {
                this.produtNotActive.push({
                  comp_id: e.response.data.comp_id,
                  id: decodedId,
                });
              }
            } else {
              this.$httpError(e, "ItemCameraComponent - getCompId");
            }
          });

        this.paused = false;
      });
    }, 500),
  },
};
</script>

<style scoped>
@import "@/assets/scss/camera.scss";

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.acf-field {
  margin-bottom: 1rem;
}
.row {
  margin-right: 0px;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.inner-container {
  width: 100%;
  background-color: #f7f5ee;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.product-wrapper {
  overflow-x: auto;
  max-height: calc(100vh - 370px);
}

@media screen and (min-width: 768px) {
  .product-wrapper {
    overflow-x: auto;
  }

  .camera-wrapper {
    height: 28vh;
  }
}
</style>
