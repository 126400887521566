<template>
  <LoadingOverlay v-if="isLoading" />

  <GreenifiedCard :grColor="false" v-else>
    <template #cardContent>
      <HandleViewHeader
        :text="$t('pageHeaders.orderLabels')"
        @return="$emit('close')"
      />
      <HandleViewRow>
        <GrDropdown
          :topMargin="5"
          :textLabel="$t('placeholders.address')"
          :placeholder="$t('placeholders.address')"
          @update:modelValue="(address) => setOrder(address)"
          :options="addresses"
          label="building"
          :showError="!labelOrder && showError"
          :errorText="$t('errorMessage.chooseAddress')"
        />
      </HandleViewRow>

      <HandleViewRow>
        <InputField
          :label="`${$t('placeholders.recipient')} *`"
          :placeHolder="$t('placeholders.name')"
          v-model="recipient"
          :showError="!recipient && showError"
          :errorText="$t('errorMessage.enterRecipient')"
        />
      </HandleViewRow>
      <HandleViewRow>
        <InputField
          :placeHolder="$t('placeholders.phoneNumber')"
          v-model="phone"
          type="number"
        />
      </HandleViewRow>

      <HandleViewRow>
        <InputField
          :label="`${$t('placeholders.labelQuantity')} *`"
          :placeHolder="$t('placeholders.labelQuantity')"
          type="number"
          v-model="quantity"
          :showError="!quantity && showError"
          :errorText="$t('errorMessage.enterNumber')"
        />
      </HandleViewRow>
      <HandleViewRow>
        <ClickableText
          :addIcon="!showTextField"
          :text="$t('button.addComment')"
          @clicked="showTextField = true"
        />
        <InputTextField
          v-if="showTextField"
          :placeHolder="$t('placeholders.labelQuantity')"
          v-model="note"
        />
      </HandleViewRow>
      <HandleViewFooter
        :confirmText="$t('pageHeaders.orderLabels')"
        @confirm="
          () => {
            if (!hasRequiredFields) {
              showError = true;
            } else {
              orderLabels();
              showError = false;
            }
          }
        "
      />
    </template>
  </GreenifiedCard>
</template>

<script>
import item from "@/item/services/api/item";
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";
import InputField from "@/shared/components/InputField.vue";
import GrDropdown from "@/shared/components/GrDropdown.vue";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";
import InputTextField from "@/shared/components/InputTextField.vue";
import ClickableText from "@/shared/components/ClickableText.vue";
import HandleViewHeader from "./HandleViewHeader.vue";
import HandleViewRow from "./HandleViewRow.vue";
import HandleViewFooter from "./HandleViewFooter.vue";

export default {
  data() {
    return {
      showTextField: false,
      labelOrder: null,
      recipient: null,
      phone: null,
      note: null,
      quantity: null,
      projectNumber: this.$store.getters.project_settings.projekt,
      showError: false,
      addresses: null,
      isLoading: true,
    };
  },
  emits: ["close", "update"],
  components: {
    GreenifiedCard,
    InputField,
    GrDropdown,
    LoadingOverlay,
    InputTextField,
    ClickableText,
    HandleViewHeader,
    HandleViewRow,
    HandleViewFooter,
  },
  computed: {
    hasRequiredFields() {
      let requiredFields = false;
      if (this.labelOrder && this.quantity && this.recipient) {
        requiredFields = true;
      }
      return requiredFields;
    },
  },
  methods: {
    setOrder(address) {
      this.labelOrder = {
        address: address.id,
        zip: address.address.postnummer,
        city: address.address.ort,
        country: address.address.land,
      };
    },
    getAddresses() {
      this.addresses = null;
      this.isLoading = true;
      item.getAddresses(this.projectNumber).then((response) => {
        this.addresses = response.data;
        this.isLoading = false;
      });
    },
    orderLabels() {
      if (!this.hasRequiredFields) {
        return;
      }
      this.isLoading = true;
      let orderToPost = {
        address: this.labelOrder.address,
        zip: this.labelOrder.zip,
        city: this.labelOrder.city,
        country: this.labelOrder.country,
        who: this.recipient,
        quantity: this.quantity,
        phone: this.phone,
      };
      item.postLabelOrder(this.projectNumber, orderToPost).then(() => {
        this.isLoading = false;
        this.$emit("update");
      });
    },
  },
  mounted() {
    this.getAddresses();
  },
};
</script>

<style scoped></style>
