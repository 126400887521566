<template>
  <LoadingOverlay v-if="isLoading" />
  <div v-else>
    <LayoutHeader v-if="getBackFunction">
      <div class="row">
        <div class="col-12 col-md-1 d-flex justify-content-between">
          <IconButton
            :width="24"
            :height="24"
            style="justify-content: start !important"
            @click="
              () => {
                unscannedProducts =
                  productsToScan.length -
                  productsToScan.filter((element) => element.comp_id).length;
                if (openCamera && unscannedProducts !== 0) {
                  incorrectScanAmount = true;
                } else {
                  getBackFunction();
                  $refs.itemcomp.scrollToContainer();
                }
              }
            "
            :img="'arrow-left.svg'"
          ></IconButton>
          <div v-if="openCamera" class="d-flex align-items-center">
            <Transition mode="out-in" name="flash-green-bold">
              <div
                :key="
                  productsToScan.filter((element) => element.comp_id).length
                "
              >
                {{ productsToScan.filter((element) => element.comp_id).length }}
              </div>
              &nbsp; </Transition
            >/&nbsp;{{ productsToScan.length }}
          </div>
        </div>
      </div>
    </LayoutHeader>
    <div v-if="startScreen">
      <FormGroup v-if="!openCamera && !(openSameProductEditIndex !== null)">
        <FormTitle
          :title="$t('label.registerNewProduct')"
          :paragraph="$t('paragraph.youHaveScannedEmptyProduct')"
        >
        </FormTitle>

        <FormRow :noLabel="true">
          <div class="col-12 col-sm-6 p-0 m-0">
            <GreenifiedButton
              :noWrap="true"
              @click="newProduct = true"
              type="black"
              :text="$t('button.registerNewProduct')"
            />
          </div>
        </FormRow>
        <FormRow :noLabel="true" :no-wrap="true">
          <div class="col-12 col-sm-6 p-0 m-0">
            <GreenifiedButton
              :noWrap="true"
              :overflow="true"
              :text="$t('button.basedOnPrevProduct')"
              @click="showHistory = true"
            />
          </div>
        </FormRow>
      </FormGroup>
    </div>

    <HistoryView
      v-else-if="showHistory"
      :currentItem="item_t"
      @update:modelValue="
        (event) => {
          fetchHistory(event);
        }
      "
    ></HistoryView>
    <div v-else>
      <div class="container" style="--bs-gutter-x: 0rem">
        <FormGroup
          :stickyTop="true"
          v-if="!openCamera && !(openSameProductEditIndex !== null)"
        >
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 d-flex">
              <label class="subheading-tiny"
                >{{
                  newProduct
                    ? $t("label.registerNewProduct")
                    : $t("menuTitle.editProduct")
                }}&nbsp;</label
              >
              <label class="subheading-tiny" style="font-weight: 400">/</label>
              <label v-if="item_t" class="subheading-tiny">
                &nbsp;{{ `ID ${item_t.comp_id}` }}
              </label>
            </div>
          </div>
        </FormGroup>

        <div
          v-show="
            !openCamera &&
            openSameProductEditIndex === null &&
            openNewProductEditIndex === null
          "
        >
          <itemComponent
            @openCameraComponent="
              (event) => {
                productsToScan = event;
                openCamera = true;
              }
            "
            ref="itemcomp"
            v-if="item_t && !isLoading"
            :item="item_t"
            :key="item_t"
            v-model:productsToScan="productsToScan"
            @openSameProductEdit="(event) => openSameProductEdit(event)"
            @openNewProductEdit="(event) => openNewProductEdit(event)"
            @scrollTo="(position) => (elementPosition = position)"
            :newProduct="newProduct"
          ></itemComponent>
        </div>
        <itemCameraComponent
          :productsToScan="productsToScan"
          v-if="openCamera && openNewProductEditIndex === null"
          :item="item_t"
          @openNewProductEdit="(event) => openNewProductEdit(event)"
          :key="productsToScan"
          @return="
            getBackFunction();
            $refs.itemcomp.scrollToContainer();
          "
        ></itemCameraComponent>
        <itemUniqueComponent
          v-if="openSameProductEditIndex !== null"
          @update:modelValue="
            () => {
              openNewProductEditIndex = null;
              window.setTimeout(() => {
                window.scrollTo({
                  top: elementPosition,
                  left: 0,
                  behavior: 'instant',
                });
                elementPosition = null;
              }, 100);
            }
          "
          :key="item_t.same_products[openSameProductEditIndex]"
          v-model="item_t.same_products[openSameProductEditIndex]"
        ></itemUniqueComponent>
        <itemUniqueComponent
          v-if="openNewProductEditIndex !== null"
          @update:modelValue="
            () => {
              openNewProductEditIndex = null;
              window.setTimeout(() => {
                window.scrollTo({
                  top: elementPosition,
                  left: 0,
                  behavior: 'instant',
                });
                elementPosition = null;
              }, 100);
            }
          "
          :key="productsToScan[openNewProductEditIndex]"
          v-model="productsToScan[openNewProductEditIndex]"
        ></itemUniqueComponent>
      </div>
    </div>
  </div>
  <VerificationModal
    v-if="confirmOverwrite"
    :text="$t('label.areYouSureOverwrite')"
    :title="$t('label.confirmation')"
    @confirm="confirmedOverwrite()"
    @close="confirmOverwrite = false"
  />

  <VerificationModal
    v-if="incorrectScanAmount"
    @confirm="clearInvalidProducts()"
    @close="incorrectScanAmount = false"
    :title="$t('verificationTitles.unscannedProducts')"
    :text="$t('verificationTexts.verifyUnscannedProducts', [unscannedProducts])"
  />
</template>

<script>
import item_api from "@/item/services/api/item";
import itemComponent from "../components/ItemComponent";
import VerificationModal from "@/shared/modals/VerificationModal.vue";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import itemUniqueComponent from "../components/ItemUniqueComponent";
import itemCameraComponent from "../components/ItemCameraComponent";
import FormGroup from "@/shared/components/FormGroup.vue";
import IconButton from "@/shared/components/IconButton.vue";
import LayoutHeader from "@/shared/components/LayoutHeader.vue";
import FormTitle from "@/shared/components/FormTitle.vue";
import FormRow from "@/shared/components/FormRow.vue";
import HistoryView from "@/item/views/HistoryView.vue";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";
import router from "../router";
export default {
  name: "indexView",
  components: {
    itemComponent,
    VerificationModal,
    itemCameraComponent,
    itemUniqueComponent,
    FormGroup,
    IconButton,
    LayoutHeader,
    FormTitle,
    FormRow,
    GreenifiedButton,
    HistoryView,
    LoadingOverlay,
  },

  data() {
    return {
      window: window,
      elementPosition: null,
      item_t: null,
      item_temp: null,
      showHistory: false,
      confirmOverwrite: false,
      searchHistoryValue: "",
      openCamera: false,
      productsToScan: [{}],
      openSameProductEditIndex: null,
      openNewProductEditIndex: null,
      newProduct: false,
      incorrectScanAmount: false,
      unscannedProducts: null,
      isLoading: true,
    };
  },

  computed: {
    startScreen() {
      return (
        this.item_t &&
        Object.keys(this.item_t?.green_struc).length === 0 &&
        !this.newProduct &&
        !this.showHistory
      );
    },
    getBackFunction() {
      if (this.openNewProductEditIndex !== null) {
        return () => {
          this.openNewProductEditIndex = null;
        };
      }
      if (this.openCamera) {
        return () => {
          this.openCamera = false;

          this.$refs.itemcomp.scrollToContainer();
        };
      }
      if (this.openSameProductEditIndex !== null) {
        return () => {
          this.openSameProductEditIndex = null;
        };
      }

      return null;
    },
    item() {
      let item_store = this.currentItem;
      if (
        item_store &&
        item_store.location === null &&
        (this.$store.getters.project_settings.alla_rum ?? []).find(
          (elem) => elem.rum === String(this.$store.getters.last_location_id)
        )
      ) {
        item_store.location = String(this.$store.getters.last_location_id);
      }
      return item_store;
    },
  },

  methods: {
    getItem() {
      this.isLoading = true;
      item_api
        .getItem(this.$route.params.id)
        .then(async (res) => {
          if (res.data.project_status.active === "0") {
            return this.$router.push({
              name: "notAuthorized",
              params: { authType: "I" },
            });
          }
          await this.$store.dispatch("setProjectSettings", res.data.project);

          if (!this.$store.getters.language) {
            const projectLanguage = this.$store.getters.project_settings.sprak;
            this.$store.dispatch("updateLanguage", projectLanguage);
            this.$i18n.locale = projectLanguage;
          }

          this.item_t = res.data;
          if (Object.keys(this.item_t.green_struc).length === 0) {
            this.productsToScan[0] = {
              id: this.item_t.id,
              comp_id: this.item_t.comp_id,
              productSpecificImages: [],
              damages: null,
              productSpecifics: {},
            };
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            router.push({ name: "notAuthorized" });
          } else {
            this.$httpError(error, "Index - getItem");
          }
        });
    },
    clearInvalidProducts() {
      let validProducts = this.productsToScan.filter((product) => {
        if ("comp_id" in product && product.comp_id !== null) {
          return product;
        }
      });
      this.productsToScan = validProducts;
      if (
        !this.productsToScan.length ||
        !("comp_id" in this.productsToScan[0]) ||
        this.productsToScan[0]?.comp_id === null
      ) {
        this.$refs.itemcomp.$data.preBluredQuantity = 1;
        this.$refs.itemcomp.$data.addCountNewProducts = 1;
        this.$refs.itemcomp.$data.clickedAddMoreProducts = false;
      } else {
        this.$refs.itemcomp.$data.preBluredQuantity =
          this.productsToScan.length;
        this.$refs.itemcomp.$data.addCountNewProducts =
          this.productsToScan.length;
      }

      this.getBackFunction();
      this.$refs.itemcomp.scrollToContainer();
    },
    fetchHistory(event) {
      this.item_t.green_struc = event.green_struc;
      this.item_t.supplier_code = event.supplier_code;
      this.newProduct = true;

      this.showHistory = false;
    },
    openSameProductEdit(event) {
      this.openSameProductEditIndex = event;
    },
    openNewProductEdit(event) {
      this.openNewProductEditIndex = event;
    },
    setItemOverrite(id) {
      item_api
        .getItem(id)
        .then((response) => {
          let temp_item = response.data;
          temp_item["id"] = this.item_t.id;
          temp_item["rum"] = this.$store.getters.last_location_id;
          temp_item["skador"] = null;
          temp_item["status"] = "J";
          this.item_t = temp_item;
        })
        .catch((e) => {
          if (e.request?.status === 401) {
            this.dispatch("auth/logout");
            this.$router.push({
              path: "/login",
              query: { message: "errorlabel.unauthorized" },
            });
          }
          if (e.request?.status === 404) {
            alert("Unknown error");
          }
          this.$httpError(e, "Index - getItemOverrite");
        });
    },

    confirmedOverwrite() {
      if (this.searchHistoryValue !== "") {
        this.setItemOverrite(this.item_temp.id);
      } else {
        this.item_temp["id"] = this.item.id;
        this.item_temp["rum"] = this.$store.getters.last_location_id;
        this.item_temp["skador"] = null;
        this.item_temp["status"] = "J";

        this.item_t = this.item_temp;
      }
      this.item_temp = null;
      this.confirmOverwrite = false;
      this.showHistory = false;
    },
  },
  mounted() {
    this.getItem();
  },
};
</script>
