<template>
  <PageHeader isSticky :gr-color="false">
    <template #headerContent>
      <div class="row">
        <div class="col-12 subheading-medium">
          {{ $t("menu.history") }}
        </div>
      </div>
      <div class="col-12 col-sm-8 col-md-6 d-flex mt-4 pb-3">
        <div class="col-8">
          <SearchField
            :placeHolder="$t('button.search')"
            @input="debounceInput"
          />
        </div>
        <div class="col-4 justify-content-end d-flex">
          <GreenifiedButton
            :disabled="productMarked === null"
            type="black"
            size="thin"
            @clicked="openAreYouSureSave($event)"
            :text="$t('button.fetch')"
          />
        </div>
      </div>
    </template>
  </PageHeader>

  <GreenifiedCard :gr-color="false" :hidePadding="'top'">
    <template #cardContent>
      <div class="position-relative">
        <LoaderSpinnerLocal v-if="loadingHistory" />
        <div class="row">
          <HistoryRow
            v-for="row in history"
            :key="row"
            @click="(event) => (productMarked = event)"
            :row="row"
            :marked="
              productMarked && productMarked.green_struc === row.green_struc
            "
          />
        </div>
        <div class="row justify-content-center mt-4" v-if="!loadingHistory">
          <div class="col-12 col-md-4">
            <GreenifiedButton
              v-if="history"
              :disabled="history.length === searchHits"
              type="black"
              :text="$t('button.fetchMore')"
              @clicked="
                offset += 20;
                search(searchHistoryValue);
              "
            />
          </div>
        </div>
      </div>
    </template>
  </GreenifiedCard>
  <VerificationModal
    v-if="confirmOverwrite"
    :text="$t('label.areYouSureOverwrite')"
    :title="$t('label.confirmation')"
    @confirm="confirmedOverwrite()"
    @close="confirmOverwrite = false"
  />
</template>
<script>
import item_api from "../services/api/item";
import debounce from "debounce";
import VerificationModal from "@/shared/modals/VerificationModal.vue";
import SearchField from "@/shared/components/SearchField.vue";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import HistoryRow from "../components/HistoryRow.vue";
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";
import LoaderSpinnerLocal from "@/shared/components/LoaderSpinnerLocal.vue";
import PageHeader from "@/shared/components/PageHeader.vue";
export default {
  name: "indexView",
  components: {
    VerificationModal,
    SearchField,
    GreenifiedButton,
    HistoryRow,
    GreenifiedCard,
    LoaderSpinnerLocal,
    PageHeader,
  },
  props: {
    currentItem: { type: Object, default: null },
  },
  data() {
    return {
      searchHits: 0,
      item_t: null,
      item_temp: null,
      showHistory: false,
      confirmOverwrite: false,
      searchHistoryValue: "",
      searchRes: {},
      loadingHistory: false,
      offset: 0,
      productsToScan: [{}],
      productMarked: null,
      historyProducts: [],
    };
  },

  computed: {
    history() {
      return this.searchRes[this.searchHistoryValue];
    },
    item() {
      let item_store = this.currentItem;
      if (item_store && item_store.rum === null) {
        item_store.rum = this.$store.getters.last_location_id;
      }
      return item_store;
    },
  },
  mounted() {
    this.search("");
  },
  methods: {
    openAreYouSureSave() {
      this.$emit("update:modelValue", this.productMarked);
    },
    search(text) {
      this.loadingHistory = true;
      item_api
        .getProjectItems(
          this.$store.getters.project_nr,
          text,
          this.offset,
          this.$i18n.locale
        )
        .then((response) => {
          this.historyProducts = response.data.items;
          this.searchHits = response.data.hits;
          if (this.searchRes?.[text]) {
            this.historyProducts.forEach((product) => {
              this.searchRes[text].push(product);
            });
          } else {
            this.searchRes[text] = this.historyProducts;
          }

          this.loadingHistory = false;
        })
        .catch((e) => {
          this.$httpError(e, "HistoryView - getProjectItems");
        });
    },
    debounceInput: debounce(function (e) {
      this.offset = 0;
      this.productMarked = null;
      this.searchHistoryValue = e.target.value;
      if (!(e.target.value in this.searchRes)) {
        this.search(e.target.value);
      }
    }, 500),

    setItemOverrite(id) {
      item_api
        .getItem(id)
        .then((response) => {
          let temp_item = response.data;
          temp_item["id"] = this.currentItem.id;
          temp_item["rum"] = this.$store.getters.last_location_id;
          temp_item["skador"] = null;
          temp_item["status"] = "J";
          this.item_t = temp_item;
        })
        .catch((e) => {
          if (e.request?.status === 401) {
            this.dispatch("auth/logout");
            this.$router.push({
              path: "/login",
              query: { message: "errorlabel.unauthorized" },
            });
          }
          if (e.request?.status === 404) {
            alert("Unknown error");
          }
          this.$httpError(e, "HistoryView - getItem");
        });
    },

    confirmedOverwrite() {
      if (this.searchHistoryValue !== "") {
        this.setItemOverrite(this.item_temp.id);
      } else {
        this.item_temp["id"] = this.item.id;
        this.item_temp["rum"] = this.$store.getters.last_location_id;
        this.item_temp["skador"] = null;
        this.item_temp["status"] = "J";

        this.item_t = this.item_temp;
      }
      this.item_temp = null;
      this.confirmOverwrite = false;
      this.showHistory = false;
    },
  },
  watch: {
    currentItem(newItem) {
      this.item_t = newItem;
      if (Object.keys(this.item_t.green_struc).length === 0) {
        this.productsToScan[0] = {
          id: this.item_t.id,
          comp_id: this.item_t.comp_id,
          productSpecificImages: [],
          damages: null,
          productSpecifics: {},
        };
      }
    },
  },
};
</script>
