<template>
  <GreenifiedCard>
    <template #cardContent>
      <div class="d-flex flex-column h-full">
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="subheading-large">
              <p v-if="authType === 'A'">
                {{
                  $t("paragraph.userHasNoAccess", [$t("subHeadings.project")])
                }}
              </p>
              <p v-if="authType === 'P'">
                {{ $t("paragraph.noActiveProjects") }}
              </p>
              <p v-if="authType === 'I'">
                {{ $t("paragraph.projectCompletedInfo") }}
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-6 col-sm-5 col-md-4 col-lg-3">
            <GreenifiedButton
              type="black"
              :text="$t('menu.home')"
              @click="
                router.push({
                  name: 'welcome',
                })
              "
            />
          </div>
        </div>
      </div>
    </template>
  </GreenifiedCard>
</template>

<script>
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import GreenifiedCard from "../../shared/components/GreenifiedCard.vue";
import router from "../router";
export default {
  data() {
    return {
      router: router,
      authType: this.$route.params.authType ?? "A",
    };
  },

  components: { GreenifiedCard, GreenifiedButton },
};
</script>
