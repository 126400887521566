<template>
  <div class="row subheading-small table-header">
    <slot name="thContent" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.table-header {
  height: 3rem;
  align-items: center;
}
</style>
