<template>
  <div
    class="col-12 col-md-6 col-lg-4 subheading-medium d-flex align-items-center mb-3 justify-center"
    @click="$emit('selected', !isSelected ? project.projektnr : null)"
  >
    <div
      class="gr-color d-flex align-items-center justify-content-center project-button"
      :class="isSelected && 'selected-bg'"
    >
      <p>{{ project.projektbenamn }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: { type: Object, default: null },
    isSelected: { type: Boolean, default: false },
  },
  emits: ["selected"],
};
</script>

<style scoped>
.project-button {
  height: 3.6rem;
  width: 100%;
}

.selected-bg {
  background-color: rgb(234, 223, 196);
}

@media screen and (min-width: 768px) {
  .project-button {
    width: 90%;
  }

  .justify-center {
    justify-content: center;
  }
}
</style>
