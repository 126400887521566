<template>
  <HandleViewContent
    @refresh="getRooms"
    @post="postRooms"
    :view="0"
    :inputKey="'rumsnamn'"
    :rowKey="'rum'"
    :errorText="$t('errorMessage.missingRoomName')"
    :addText="$t('button.addRoom')"
    :phText="$t('paragraph.roomsHandler')"
    :phTitle="$t('pageHeaders.roomOverview')"
    :tableData="rooms"
    :isLoading="isLoading"
  />
</template>

<script>
import item from "../services/api/item.js";
import HandleViewContent from "../components/HandleViewContent.vue";
export default {
  data() {
    return {
      isLoading: true,
      rooms: null,
      projectNumber: this.$store.getters.project_settings.projekt,
      body: { delete: [], insert: [], update: [] },
    };
  },
  components: {
    HandleViewContent,
  },
  methods: {
    getRooms() {
      this.isLoading = true;
      this.rooms = null;

      item
        .getRooms(this.projectNumber)
        .then((response) => {
          this.rooms = response.data;
          this.isLoading = false;
        })
        .catch((e) => {
          this.$httpError(e, "HandleRooms - getRooms");
        });
    },
    postRooms() {
      let missingRoomName = this.rooms.find((room) => !room.rumsnamn);
      if (!missingRoomName) {
        this.body.update = this.rooms;
        this.isLoading = true;
        item
          .postRooms(this.projectNumber, this.body)
          .then(() => {
            this.getRooms();
          })
          .catch((e) => {
            this.$httpError(e, "HandleRooms - postRooms");
          });
      }
    },
  },
  mounted() {
    this.getRooms();
  },
};
</script>

<style scoped></style>
