import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import item_api from "@/item/services/api/item";
import item from "@/item/services/api/item";

export default new Vuex.Store({
  state: {
    isLoading: false,
    language: null,
    project_settings: null,
    last_location_id: null,
    currentProject: null,
    history: [],
    quantities: {},
    maxQuantity: 1,
    userProjects: null,
    cameraPermission: false,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    last_location_id(state) {
      return state.last_location_id;
    },
    language(state) {
      return state.language;
    },
    project_nr(state) {
      return state.project_settings?.projekt;
    },
    project_settings(state) {
      return state.project_settings;
    },
    currentProject(state) {
      return state.currentProject;
    },

    struc(state) {
      return state.project_settings.struc;
    },

    history(state) {
      return state.history;
    },
    quantities(state) {
      return state.quantities;
    },
    maxQuantity(state) {
      return state.maxQuantity;
    },
    cameraPermission(state) {
      return state.cameraPermission;
    },
  },
  mutations: {
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_CURRENT_PROJECT(state, value) {
      state.currentProject = value;
    },
    SET_QUANTITIES(state, value) {
      state.quantities = value;
    },
    SET_MAX_QUANTITIES(state, value) {
      state.maxQuantity = value;
    },
    SET_NOTIFICATION(state, value) {
      state.notification = value;
    },
    SET_LAST_LOCATION(state, value) {
      state.last_location_id = value;
    },
    SET_LANG(state, value) {
      state.language = value;
    },
    SET_PROJECT_SETTINGS(state, value) {
      state.project_settings = value;
      item_api
        .getGreenifiedStructure(state.project_settings.projekt)
        .then((response) => {
          state.project_settings.struk = response.data;
        })
        .catch();
    },

    SET_PROJECT_SETTINGS_SUPPLIERS(state, value) {
      state.project_settings.lev = value;
    },
    APPEND_HISTORY(state, value) {
      if (Object.keys(value.green_struk).length > 0) {
        if (
          state.history.length === 0 ||
          JSON.stringify(value.green_hash) !==
            JSON.stringify(state.history[0].green_hash)
        ) {
          state.history.unshift(value);

          if (state.history.length > 30) {
            state.history.pop();
          }
        } else {
          state.history[0] = value;
        }
      }
    },
    SET_CAMERA_PERMISSION(state, value) {
      state.cameraPermission = value;
    },
  },
  actions: {
    calculateMaxQuantity({ commit }, lines) {
      let max = 1;
      Object.keys(lines).forEach(function (key) {
        if (max < lines[key].items.length) {
          max = lines[key].items.length;
        }
      });
      commit("SET_MAX_QUANTITIES", max);
    },
    getQuantities({ commit, getters }, guestToken) {
      if (getters.project_settings) {
        item
          .getQuantity(getters.project_settings.projekt, guestToken)
          .then((response) => {
            commit("SET_QUANTITIES", response.data);
          })
          .catch();
      }
    },
    setIsLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
    updateLanguage({ commit }, Lang) {
      commit("SET_LANG", Lang);
    },
    changeLanguage({ commit, getters }, Lang) {
      commit("SET_LANG", Lang);
      if (getters.project_settings) {
        this.dispatch("setProjectSettings", getters.project_settings?.projekt);
      }
    },
    setProjectSettingsSuppliers({ commit }, suppliers) {
      commit("SET_PROJECT_SETTINGS_SUPPLIERS", suppliers);
    },

    async setProjectSettings({ commit }, project_nr) {
      commit("SET_CURRENT_PROJECT", project_nr);
      await item_api.getProjectSettings(project_nr).then((response) => {
        commit("SET_PROJECT_SETTINGS", response.data);
      });
    },
    addToHistory({ commit }, data) {
      commit("APPEND_HISTORY", data);
      commit("SET_LAST_LOCATION", data.location);
    },
  },
  plugins: [createPersistedState()],
  modules: {
    auth,
  },
});
