import axios from "axios";
import store from "../store";

const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default () => {
  return axios.create({
    baseURL:
      process.env[
        "VUE_APP_GREENIFIED_API_" + store.state.language.toUpperCase()
      ],
    withCredentials: true,
    headers: header,
  });
};
