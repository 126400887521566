<template>
  <LoadingOverlay v-if="isLoading" />
  <GreenifiedCard :grColor="false" v-if="!isLoading">
    <template #cardContent>
      <HandleViewHeader
        :text="
          roomToEdit
            ? `${$t('pageHeaders.editRoom')} - ${room.rumsnamn}`
            : $t('pageHeaders.createNewRoom')
        "
        @return="$emit('close')"
      />
      
      <HandleViewRow :topMargin="5">
        <GrDropdown
          size="full"
          :placeholder="$t('placeholders.building')"
          v-model="room.hus"
          :options="locations"
          label="building"
          reduce="id"
        />
      </HandleViewRow>
      <HandleViewRow>
        <InputField
          :placeHolder="$t('placeholders.floor')"
          v-model="room.van"
          type="number"
        />
      </HandleViewRow>

      <HandleViewRow>
        <InputField :placeHolder="'Rumskod'" v-model="room.rumskod" />
      </HandleViewRow>

      <HandleViewRow>
        <InputField
          :placeHolder="$t('placeholders.roomName')"
          v-model="room.rumsnamn"
          :showError="showError && !newRoom.rumsnamn"
          :errorText="$t('errorMessage.missingRoomName')"
        />
      </HandleViewRow>
      <HandleViewRow>
        <GreenifiedCheckbox
          :label="$t('tableHeaders.newBuilding')"
          v-model="room.new_room"
          :trueValue="1"
          :falseValue="0"
        />
      </HandleViewRow>

      <HandleViewFooter
        :confirmText="
          roomToEdit ? $t('button.saveRoom') : $t('button.createRoom')
        "
        :cancelText="$t('button.remove')"
        @cancel="roomToEdit ? (verifyDelete = true) : $emit('close')"
        @confirm="
          () => {
            if (!room.rumsnamn) {
              showError = true;
            } else {
              postRoom();
              showError = false;
            }
          }
        "
      />
    </template>
  </GreenifiedCard>
  <VerificationModal
    v-if="verifyDelete"
    @close="verifyDelete = false"
    @confirm="deleteRoom()"
    :title="$t('verificationTitles.removeRoom')"
    :text="$t('verificationTexts.verifyRemoveRoom', [room.rumsnamn])"
  />
</template>

<script>
import item from "@/item/services/api/item";
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";
import InputField from "@/shared/components/InputField.vue";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";
import GrDropdown from "@/shared/components/GrDropdown.vue";
import VerificationModal from "@/shared/modals/VerificationModal.vue";
import GreenifiedCheckbox from "@/shared/components/GreenifiedCheckbox.vue";
import HandleViewHeader from "./HandleViewHeader.vue";
import HandleViewFooter from "./HandleViewFooter.vue";
import HandleViewRow from "./HandleViewRow.vue";

export default {
  data() {
    return {
      projectNumber: this.$store.getters.project_settings.projekt,
      locations: null,
      showError: false,
      isLoading: true,
      verifyDelete: false,
      newRoom: {
        rumsnamn: null,
        rumskod: null,
        van: null,
        hus: null,
        enhet: null,
        new_room: 0,
      },
      room: null,
      body: { delete: [], insert: [], update: [] },
    };
  },
  props: {
    roomToEdit: { type: Object, default: null },
  },
  emits: ["close", "update"],
  components: {
    GreenifiedCard,
    InputField,
    LoadingOverlay,
    GrDropdown,
    VerificationModal,
    GreenifiedCheckbox,
    HandleViewHeader,
    HandleViewFooter,
    HandleViewRow,
  },
  methods: {
    getAddresses() {
      item
        .getAddresses(this.projectNumber)
        .then((response) => {
          this.locations = response.data;
          this.room = this.roomToEdit ? this.roomToEdit : this.newRoom;
          this.isLoading = false;
        })
        .catch((e) => {
          this.$httpError(e, "EditRoom - getAddresses");
        });
    },
    deleteRoom() {
      this.isLoading = true;
      this.body.delete.push(this.room);
      this.postRoom();
    },
    postRoom() {
      if (this.showError) {
        return;
      }
      if (!this.verifyDelete) {
        if (this.roomToEdit) {
          this.body.update.push(this.room);
        } else {
          this.body.insert.push(this.room);
        }
      }
      this.verifyDelete = false;
      item
        .postRooms(this.projectNumber, this.body)
        .then(() => {
          this.$emit("update");
        })
        .catch((e) => {
          this.$httpError(e, "EditRoom - postRooms");
        });
    },
  },
  mounted() {
    this.getAddresses();
  },
};
</script>

<style scoped>
.return-icon-wrapper {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
}

.return-icon {
  transform: rotate(90deg);
}
</style>
