<template>
  <ArticleInformationContainer
    :breadCrumbs="breadCrumbs"
    :contentUrl="imageUrl"
    :article="article"
    :articleInformation="articleInformation"
    :buttons="buttons"
    :showId="true"
    v-if="!isLoading"
    @clicked="router.push({ name: 'Item', params: { action: 'edit' } })"
  />
  <LoadingOverlay v-else />
</template>

<script>
import ArticleInformationContainer from "@/shared/components/article_information/ArticleInformationContainer.vue";
import { mapGetters } from "vuex";
import router from "../router";
import item_api from "../services/api/item.js";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";

export default {
  components: { ArticleInformationContainer, LoadingOverlay },
  data() {
    return {
      router: router,
      buttons: [
        {
          type: "black",
          text: "menuTitle.editProduct",
          event: "edit",
        },
      ],
      breadCrumbs: null,
      imageUrl: process.env.VUE_APP_CONTENT_API,
      article: null,
      articleInformation: [],
      isLoading: true,
      greenifiedItem: null,
      userCanEditProduct: true,
    };
  },
  computed: {
    language() {
      return this.getLanguage();
    },
  },
  methods: {
    ...mapGetters({
      getLanguage: "language",
    }),
    getPublicItem() {
      item_api
        .getPublicItem(this.$route.params.id)
        .then(async (response) => {
          this.userCanEditProduct = this.$store.getters[
            "auth/userProjects"
          ]?.find((project) => project.projektnr === response.data.project)
            ? true
            : false;

          this.greenifiedItem = response.data;
          if (
            !this.userCanEditProduct &&
            this.$store.getters["auth/authenticated"]
          ) {
            this.buttons[0].type = "disabled";
            this.buttons[0].text = "button.noAccessToProject";
            this.buttons[0].disabled = true;
          }

          if (this.greenifiedItem.project_status.active === "0") {
            this.buttons[0].type = "disabled";
            this.buttons[0].text = "button.projectCompleted";
            this.buttons[0].disabled = true;
          }

          this.setItemData();
          this.isLoading = false;
        })
        .catch((error) => {
          this.$httpError(error, "ArticleInformation - getPublicItem");
        });
    },
    setItemData() {
      this.articleInformation.push(
        {
          label: "subHeadings.appearance",
          text: this.greenifiedItem.description,
          textValues: null,
          acccordion: null,
        },
        {
          label: "subHeadings.location",
          text: this.greenifiedItem.room.roomName,
          textValues: null,
          accordion: null,
        },
        {
          label: "subHeadings.toRoom",
          text: this.greenifiedItem.to_room.roomName,
          textValues: null,
          accordion: null,
        },
        {
          label: "subHeadings.totalQuantity",
          text: "paragraph.amountSplitOnRooms",
          textValues: () => {
            let totalAmount = 0;
            this.getRooms(this.greenifiedItem).forEach((room) => {
              totalAmount += Number(room.amount);
            });
            return [totalAmount, this.getRooms(this.greenifiedItem).length];
          },
          accordion: {
            rows: this.getRooms(this.greenifiedItem),
            key: "amount",
            label: "subHeadings.amount",
            value: "name",
          },
        },
        {
          label: "subHeadings.measurements",
          text: null,
          textValues: null,
          accordion: {
            rows: [
              {
                label: "subHeadings.height",
                value:
                  this.greenifiedItem.green_struc.hojd &&
                  `${this.greenifiedItem.green_struc.hojd} mm`,
              },
              {
                label: "subHeadings.width",
                value:
                  this.greenifiedItem.green_struc.bredd &&
                  `${this.greenifiedItem.green_struc.bredd} mm`,
              },
              {
                label: "subHeadings.depth",
                value:
                  this.greenifiedItem.green_struc.djup &&
                  `${this.greenifiedItem.green_struc.djup} mm`,
              },
              {
                label: "subHeadings.length",
                value:
                  this.greenifiedItem.green_struc.langd &&
                  `${this.greenifiedItem.green_struc.langd} mm`,
              },
              {
                label: "subHeadings.diameter",
                value:
                  this.greenifiedItem.green_struc.diameter &&
                  `${this.greenifiedItem.green_struc.diameter} mm`,
              },
              {
                label: "subHeadings.seatHeight",
                value:
                  this.greenifiedItem.green_struc.sitthojd &&
                  `${this.greenifiedItem.green_struc.sitthojd} mm`,
              },
            ],
            key: "label",
            label: null,
            value: "value",
          },
        },
        {
          label: "subHeadings.condition",
          text: this.greenifiedItem.green_struc.skick,
          textValues: null,
          accordion: null,
        },
        {
          label: "subHeadings.contact",
          text: null,
          textValues: null,
          accordion: {
            rows: [
              {
                label: "subHeadings.email",
                value: "inventory@greenified.se",
              },
              {
                label: "subHeadings.phone",
                value: "+46 (0)200 77 00 60",
              },
            ],
            key: "label",
            label: null,
            value: "value",
          },
        }
      );
      this.breadCrumbs = {
        articleNumber: this.greenifiedItem.green_struc.produktnamn,
        mainCategory: null,
        subCategory:
          this.greenifiedItem.category_name?.[this.$i18n?.locale] ?? null,
      };

      this.article = {
        articleNumber: null,
        articleName: this.greenifiedItem.green_struc.produktnamn,
        id: this.greenifiedItem.comp_id,
        brand: this.greenifiedItem.green_struc.varumarke,
        images: Object.keys(this.greenifiedItem.images).map((key) => {
          return {
            url: this.greenifiedItem.images[key].url,
            alt: "image",
          };
        }),
      };
    },

    getRooms(greenifiedItem) {
      let tempMap = new Map();
      let rooms = [];
      greenifiedItem.same_products.forEach((product) => {
        const roomName = product.room.roomName;
        if (tempMap.has(roomName)) {
          tempMap.set(roomName, tempMap.get(roomName) + 1);
        } else {
          tempMap.set(roomName, 1);
        }
      });

      tempMap.forEach((amount, name) => {
        rooms.push({ name: name, amount: amount });
      });

      return rooms;
    },
  },
  beforeRouteUpdate() {
    this.isLoading = true;
  },
  mounted() {
    this.getPublicItem();
  },
  watch: {
    language(language) {
      this.breadCrumbs.subCategory =
        this.greenifiedItem.category_name[language];
    },
  },
};
</script>

<style scoped></style>
