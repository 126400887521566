<template>
  <div class="input-field-wrapper" @click="inputFocus()">
    <label v-if="label" class="mb-1 text-small">{{ label }}</label>
    <div class="position-relative">
      <input
        :disabled="disabled"
        :readonly="notEditable"
        class="gr-input"
        ref="inputField"
        @blur="
          () => {
            if (type === 'number' && minValue) {
              !inputValue || inputValue < minValue
                ? (inputValue = minValue)
                : '';
            }
            $emit('update:modelValue', inputValue);
            $emit('blur');
          }
        "
        :class="[
          width,
          showError && 'error',
          flashGreen && 'flash-green-animation',
          bold && 'input-bold',
        ]"
        :type="showPassword ? 'text' : type"
        :name="name"
        :required="isRequired"
        v-model="inputValue"
        :id="id"
        :placeholder="placeHolder"
        @input="
          () => {
            if (type === 'number') {
              if (maxValue && this.inputValue > this.maxValue) {
                inputValue = maxValue;
              }

              if (minValue && inputValue === 0) {
                inputValue = minValue;
              }
            }
            $emit('update:modelValue', inputValue);
          }
        "
      />
      <div
        class="input-helpers text-tiny"
        v-if="showError || showUnit || passwordHasToggle"
      >
        <img
          v-if="passwordHasToggle"
          src="@/assets/img/eye_icon.svg"
          @click="showPassword = !showPassword"
          class="eye-icon"
        />
        <p v-if="showError" class="error-text ms-2">{{ errorText }}</p>
        <p v-if="showUnit" class="ms-2">{{ unitText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue", "hasError", "update:hasError", "blur"],
  data() {
    return {
      inputValue: this.modelValue ?? this.value,
      showPassword: false,
    };
  },

  methods: {
    inputFocus() {
      this.$refs.inputField.focus();
    },
  },
  mounted() {
    if (this.setFocus) {
      this.inputFocus();
    }
  },
  props: {
    label: { type: String, default: null },
    id: { type: String, default: "Input field" },
    name: { type: String, default: "Input field" },
    type: { type: String, default: "text" },
    width: { type: [String, Number], default: null },
    modelValue: { type: [String, Number], default: null },
    showError: { type: Boolean, default: false },
    showUnit: { type: Boolean, default: false },
    errorText: { type: String, default: "Error" },
    unitText: { type: [String, Number], default: "mm" },
    placeHolder: { type: String, default: "" },
    setFocus: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    passwordHasToggle: { type: Boolean, default: false },
    maxValue: { type: Number, default: null },
    minValue: { type: Number, default: null },
    value: { type: [String, Number], default: null },
    disabled: { type: Boolean, default: false },
    notEditable: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
    flashGreen: { type: Boolean, default: false },
  },
  watch: {
    showError(error) {
      this.$emit("update:hasError", error);
    },
    modelValue() {
      this.inputValue = this.modelValue ?? this.value;
    },
  },
};
</script>

<style scoped>
@import "@/assets/scss/inputs.scss";

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 44px;
}

.eye-icon {
  width: 24px;
  height: 100%;
}

.flash-green-animation,
.flash-green-animation + .input-helpers {
  animation: flash-green 0.5s linear;
}
@keyframes flash-green {
  0%,
  100% {
    background-color: white;
  }
  50% {
    background-color: #82ab72;
  }
}
</style>
