<template>
  <PageHeader :title="phTitle" :text="phText" :grColor="false" v-if="!showEdit">
    <template #headerContent>
      <div class="col-8 col-sm-6 col-md-4 col-lg-3 mb-2">
        <GreenifiedButton :text="addText" @clicked="showEdit = true" />
      </div>
      <div class="col-4 col-sm-3 col-md-2">
        <GreenifiedButton
          @clicked="
            () => {
              if (!missingField) {
                $emit('post');
                isEditing = false;
              }
            }
          "
          :disabled="!isEditing"
          :text="$t('button.save')"
          :type="'green'"
        />
      </div>
    </template>
  </PageHeader>
  <GreenifiedCard :grColor="false" v-if="!showEdit">
    <template #cardContent>
      <TableWrapper :setLoader="isLoading">
        <template #tableContent>
          <div class="row align-items-center">
            <div
              class="col-12 col-sm-6 d-flex mb-3"
              v-for="item in tableData"
              :key="item[rowKey]"
            >
              <InputField
                v-model="item[inputKey]"
                @input="isEditing = true"
                :showError="!item[inputKey]"
                :errorText="errorText"
              />
              <div class="ms-2 position-relative">
                <EditPencil
                  @clicked="
                    () => {
                      showEdit = true;
                      dataToEdit = item;
                    }
                  "
                />
              </div>
            </div>
          </div>
        </template>
      </TableWrapper>
    </template>
  </GreenifiedCard>
  <EditAddress
    v-if="showEdit && view === 1"
    :addressToEdit="dataToEdit"
    @close="
      showEdit = false;
      dataToEdit = null;
    "
    @update="
      showEdit = false;
      $emit('refresh');
    "
  />
  <EditRoom
    v-if="showEdit && view === 0"
    :roomToEdit="dataToEdit"
    @close="
      showEdit = false;
      dataToEdit = null;
    "
    @update="
      showEdit = false;
      $emit('refresh');
    "
  />
</template>

<script>
import InputField from "@/shared/components/InputField.vue";
import PageHeader from "@/shared/components/PageHeader.vue";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import TableWrapper from "@/shared/components/TableWrapper.vue";
import EditPencil from "@/shared/components/EditPencil.vue";
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";
import EditAddress from "../components/EditAddress.vue";
import EditRoom from "../components/EditRoom.vue";
export default {
  data() {
    return {
      isEditing: false,
      dataToEdit: null,
      showEdit: false,
    };
  },
  emits: ["refresh", "post"],
  computed: {
    missingField() {
      return this.tableData.find((data) => !data[this.inputKey]);
    },
  },
  props: {
    view: { type: Number, default: null },
    inputKey: { type: [Number, String], default: null },
    rowKey: { type: [Number, String], default: null },
    errorText: { type: String, default: null },
    addText: { type: String, default: null },
    phText: { type: String, default: null },
    phTitle: { type: String, default: null },
    tableData: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: true },
  },

  components: {
    InputField,
    PageHeader,
    GreenifiedButton,
    TableWrapper,
    EditPencil,
    GreenifiedCard,
    EditAddress,
    EditRoom,
  },
};
</script>

<style scoped></style>
