<template>
  <div class="row mt-4 justify-content-end">
    <div class="col-12">
      <GreenifiedButton
        :disabled="buttons[0].disabled"
        @clicked="$emit('clicked', buttons[0].event)"
        :type="buttons[0].type"
        :text="$t(buttons[0].text)"
      />
    </div>
  </div>
  <div class="row mt-3 justify-content-end" v-if="buttons.length > 1">
    <div class="col-12 d-flex justify-content-between flex-wrap">
      <template v-for="(button, i) in buttons" :key="i">
        <div
          class="col-6"
          :class="i % 2 === 0 ? 'ps-3' : 'pe-3'"
          v-if="i !== 0"
        >
          <GreenifiedButton
            :disabled="button.disabled"
            :text="$t(button.text)"
            :longText="button.text.length > 12"
            @clicked="$emit('clicked', button.event)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import GreenifiedButton from "../GreenifiedButton.vue";

export default {
  emits: ["clicked"],
  props: {
    buttons: { type: Array, default: null },
  },
  components: { GreenifiedButton },
};
</script>
