<template>
  <div>
    <div class="row subheading-tiny" v-if="topLabel">
      <div class="col-12 col-md-10 col-lg-8 mb-3">{{ label }}</div>
    </div>
    <div class="row form-row">
      <div
        v-if="!noLabel && !topLabel"
        class="col-4 col-md-2 subheading-tiny align-items-center d-flex no-wrap"
      >
        {{ label }}
      </div>
      <div
        class="d-flex"
        :class="[
          noLabel || topLabel
            ? 'col-12 col-md-10 col-lg-8'
            : 'col-8 col-md-8 col-lg-6',
          'd-flex',
          alignCenter && 'align-items-center',
        ]"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "" },
    noLabel: { type: Boolean, default: false },
    topLabel: { type: Boolean, default: false },
    alignCenter: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .row {
    justify-content: center !important;
  }
}
</style>
