<template>
  <div class="col-12 col-lg-6" :class="galleryImages[0]?.url && 'mb-4'">
    <div class="col-12" v-if="currentMainImage">
      <div class="position-relative">
        <div class="arrow-box left">
          <img
            src="https://greenified.se/wp-content/themes/input/arrow_next.svg"
            alt="gallery-left-arrow"
            class="gallery-left-arrow"
            @click="changeMainImage('left')"
          />
        </div>
        <img
          v-if="galleryImages.length"
          :src="currentMainImage"
          v-touch:swipe.right="() => changeMainImage('right')"
          v-touch:swipe.left="() => changeMainImage('left')"
          alt="article image"
          class="main-image"
        />
        <div class="arrow-box right">
          <img
            src="https://greenified.se/wp-content/themes/input/arrow_next.svg"
            alt="gallery-right-arrow"
            class="gallery-right-arrow"
            @click="changeMainImage('right')"
          />
        </div>
      </div>
    </div>
    <div v-else class="gr-color main-image show-on-desktop"></div>
    <div
      class="col-12"
      :class="[
        galleryImages.length && 'mt-2',
        !galleryImages[0]?.url && 'show-on-desktop',
      ]"
    >
      <div class="row">
        <div class="col-12 image-gallery">
          <template v-for="image in productImages" :key="image.url">
            <img
              v-if="image.url"
              :src="image.url"
              :alt="image.alt"
              class="gallery-image gr-color mt-2"
              :class="currentMainImage === image.url && 'active-image'"
              @click="currentMainImage = image.url"
            />
            <div class="gallery-image gr-color mt-2" v-else></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mainImage: { type: String, default: null },
    galleryImages: { type: Array, default: null },
  },
  data() {
    return {
      productImages: this.galleryImages,
      currentMainImage: this.mainImage,
    };
  },
  mounted() {
    if (this.productImages.length < 4) {
      let imagesMissing = 4 - this.productImages.length;
      for (let i = 0; i < imagesMissing; i++) {
        this.productImages.push({ url: null, alt: null });
      }
    }
  },
  methods: {
    changeMainImage(direction) {
      let currentImageIndex = this.productImages.findIndex(
        (image) => image.url === this.currentMainImage
      );

      if (direction === "left") {
        let lastAvailableIndex = null;

        for (let i = this.productImages.length - 1; i !== 0; i--) {
          if (this.productImages[i].url !== null) {
            lastAvailableIndex = i;
            break;
          }
        }

        this.currentMainImage =
          currentImageIndex === 0
            ? this.productImages[lastAvailableIndex].url
            : this.productImages[currentImageIndex - 1].url;
      } else {
        let firstAvailableIndex = null;
        let validImages = [];
        this.productImages.forEach((image) => {
          if (image.url !== null) {
            validImages.push(image);
          }
        });

        for (let i = 0; i < this.productImages.length; i++) {
          if (this.productImages[i].url !== null) {
            firstAvailableIndex = i;
            break;
          }
        }
        this.currentMainImage =
          currentImageIndex === validImages.length - 1
            ? this.productImages[firstAvailableIndex].url
            : this.productImages[currentImageIndex + 1].url;
      }
    },
  },
};
</script>

<style scoped>
.show-on-desktop {
  display: none;
}

.bg-greenified {
  display: none;
}

.main-image {
  width: 100%;
  height: 342px;
  object-fit: contain;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.gallery-image {
  height: 70px;
  width: 70px;
  object-fit: contain;
  border: 1px solid transparent;
}

.active-image {
  border: 1px solid black;
}

.arrow-box {
  position: absolute;
  background-color: white;
  height: 2rem;
  width: 2rem;
  top: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}
.arrow-box:hover,
.action-box:hover {
  border: 1px solid black;
  transition: 0.2s;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

.gallery-left-arrow,
.gallery-right-arrow {
  width: 1.2rem;
  height: 1.2rem;
}

.gallery-left-arrow {
  transform: rotate(180deg);
}

@media screen and (max-width: 375px) {
  .gallery-image {
    height: 66px;
    width: 66px;
  }
}

@media screen and (min-width: 540px) {
  .gallery-image {
    height: 80px;
    width: 80px;
  }
}

@media screen and (min-width: 720px) {
  .gallery-image {
    height: 90px;
    width: 90px;
  }
}

@media screen and (min-width: 990px) {
  .bg-greenified {
    background-color: #f6f3ed;
  }

  .show-on-desktop {
    display: block;
  }
}
</style>
