<template>
  <GreenifiedCard :titleSize="'heading-small'">
    <template #cardContent>
      <div class="h-full d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-12 d-flex flex-column align-items-center">
            <p class="subheading-large">
              {{ $t("label.welcome") }}
            </p>
            <p class="subheading-medium mt-3">
              {{ $t("message.selectActionToProceed") }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </GreenifiedCard>
</template>

<script>
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";

export default {
  name: "WelcomeView",
  components: { GreenifiedCard },
};
</script>
