<template>
  <label
    :class="[childClass, showError && 'error-border']"
    class="image-wrapper"
    :for="id"
    :style="[localValue && `background-image:url(${content + localValue});`]"
  >
    {{ localValue ? "" : placeholder }}
    <input
      type="file"
      accept="image/*"
      :id="id"
      maxlength="60"
      @change="($event) => preUpload($event, field)"
      class=""
      style="
        height: 0px;
        width: 0px;
        padding: 0px;
        border: 0px !important;
        display: none;
        position: absolute;
      "
    />
    <IconButton
      v-if="removeable && this.localValue !== null"
      style="position: absolute; right: 10px; top: 0px; z-index: 1 !important"
      :width="32"
      :height="32"
      :img="'close.svg'"
      @click="
        this.localValue = null;
        this.$emit('update:modelValue', null);
      "
    ></IconButton>
    <input
      style="
        width: 0px;
        padding: 0px;
        border: 0px !important;
        position: absolute;
      "
      v-model="localValue"
      :required="required"
    />
  </label>
</template>

<script>
import itemAdministration from "@/item/services/api/item";
import IconButton from "@/shared/components/IconButton.vue";

export default {
  props: {
    placeholder: { type: String, default: "Image" },
    modelValue: { type: String, default: null },
    removeable: { type: Boolean, default: true },
    childClass: { type: [String, Array], default: null },
    id: { type: String, default: Math.random().toString(36) },
    required: { type: Boolean, default: false },
    showError: { type: Boolean, default: false },
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  computed: {
    content() {
      return process.env.VUE_APP_CONTENT_API;
    },
  },
  methods: {
    preUpload(e) {
      if (e.target.files.length > 0) {
        var form = new FormData();
        form.append("file", e.target.files[0]);
        itemAdministration
          .postImage(form)
          .then((response) => {
            this.localValue = response.data;
            this.$emit("update:modelValue", this.localValue);
          })
          .catch((e) => {
            this.$httpError(e, "ImageUploader - postImage");
          });
      }
    },
  },
  components: { IconButton },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  aspect-ratio: 1;
  width: 100%;
  border: 1.5px solid black;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  max-width: 300px;
}
</style>
