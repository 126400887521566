<template>
  <div class="table-wrapper">
    <LoadingOverlay v-if="setLoader" />
    <slot name="tableContent" />
  </div>
</template>

<style scoped>
.table-wrapper {
  overflow: auto;
  overflow-x: hidden;
  position: relative;
}
</style>

<script>
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";

export default {
  props: { setLoader: { type: Boolean, default: false } },
  components: { LoadingOverlay },
};
</script>
