<template>
  <template v-if="values">
    <template v-for="field in struc" :key="field">
      <div
        class="col-12"
        :class="'acf-' + field.type"
        v-if="
          checkConditions(field) &&
          (include ? include.includes(field.name) : !emit.includes(field.name))
        "
      >
        <div
          v-if="field.required === 1 && !values[field.key]"
          :ref="field.key"
        ></div>
        <div
          v-if="field.type !== 'group' && field.type !== 'acfe_hidden'"
          class="acf-label"
        ></div>
        <div v-if="field.type === 'group'">
          <FormGroup :class="field.wrapper.class">
            <ItemDescription
              v-model:in_values="values"
              v-model:in_other_values="other_values"
              :priceSuggestion="priceSuggestion"
              :struc="field.sub_fields"
              :key="this.values"
              :calcPrice="calcPrice"
              :ref="`group${field.key}`"
              :emit="emit"
              @priceChange="$emit('priceChange')"
              :include="include"
              :showValidation="showValidation"
              @showAddSupplier="
                (field) => {
                  $emit('showAddSupplier', field);
                }
              "
            ></ItemDescription>
          </FormGroup>
        </div>

        <ImageUploader
          v-else-if="field.type === 'image'"
          :placeholder="
            field.label +
            (field.required === 1 && field.label.length > 0 ? ' *' : '')
          "
          :showError="
            field.required === 1 && showValidation && !values[field.key]
          "
          :id="field.key"
          :required="field.required === 1"
          v-model="values[field.key]"
          childClass="me-3"
          :removeable="field.required === 0"
        >
        </ImageUploader>

        <FormRow
          v-else
          :label="
            field.label +
            (field.required === 1 && field.label.length > 0 ? ' *' : '')
          "
          :topLabel="field.type !== 'text'"
          :class="field.class"
          :required="field.required === 1"
        >
          <div
            class="html-link"
            v-if="field.type === 'message'"
            v-html="field.message.replace(findMessage, replaceMessage)"
          ></div>

          <div
            class="acf-input-wrap w-100"
            v-if="field.type !== 'message'"
            :style="field.name === 'pris' ? 'position:relative' : ''"
            style="position: relative"
          >
            <InputField
              v-if="field.type === 'text' && field.name !== 'varumarke'"
              :is-required="field.required === 1"
              type="text"
              id="name-id"
              :name="field.key"
              :showError="
                field.required === 1 &&
                showValidation &&
                (values[field.key] ?? '') === ''
              "
              :errorText="$t('label.fieldRequired')"
              :max="60"
              :show-unit="field.append !== ''"
              :unitText="field.append"
              :placeholder="field.placeholder"
              :value="values[field.key]"
              @input="
                inputText($event, field);
                if (field.name === 'pris') $emit('priceChange');
              "
            />
            <div
              class="container mt-1 vs__dropdown-menu"
              style="position: absolute"
              v-if="field.name === 'pris' && priceSuggestion.length > 0"
            >
              <div
                class="row manual-option"
                v-for="ev in priceSuggestion"
                :key="ev"
                @click="values[field.key] = ev.estimatedvalue"
              >
                <div class="col d-flex">
                  <div>{{ $t("label.priceSuggestionFromEval") }}:</div>
                  <strong class="ms-1 bold">
                    {{ getCurrencySymbol(ev.estimatedvalue) }}
                  </strong>
                </div>
              </div>
            </div>
            <input
              v-if="field.type === 'date_picker'"
              :required="field.required === 0 ? false : true"
              type="date"
              id="name-id"
              :name="field.key"
              maxlength="60"
              class="form-control input-lg"
              :placeholder="field.placeholder"
            />

            <template
              v-if="
                field.type === 'checkbox' &&
                Object.keys(field.choices).length === 1
              "
            >
              <div
                class="row"
                :style="'margin-left: 0px !important'"
                v-for="(choice, key) in field.choices"
                :key="choice"
              >
                <checkbox
                  class="ps-0"
                  v-if="field.type === 'checkbox'"
                  :required="field.required === 0 ? false : true"
                  type="checkbox"
                  :id="field.key"
                  :name="field.key"
                  maxlength="60"
                  :placeholder="field.placeholder"
                  v-model="values[field.key]"
                  :true-value="key"
                  :false-value="null"
                  @input="inputCheckbox($event, field, key, null)"
                />
                <label
                  :for="choice"
                  v-html="choice"
                  :key="choice"
                  class="col-10"
                  style="align-items: center; display: flex"
                >
                </label>
              </div>
            </template>
            <template v-if="field.type === 'true_false'">
              <div class="row" :style="'margin-left: 0px !important'">
                <checkbox
                  class="ps-0"
                  :required="field.required === 0 ? false : true"
                  :id="field.key"
                  :name="field.key"
                  maxlength="60"
                  true-value="1"
                  false-value="0"
                  :placeholder="field.placeholder"
                  :value="values[field.key]"
                  @input="inputCheckbox($event, field, 1, 0)"
                />
                <label v-html="field.message" class="col-10 checkboxlabel">
                </label>
              </div>
            </template>

            <template
              v-if="
                field.type === 'checkbox' &&
                Object.keys(field.choices).length > 1
              "
            >
              <ul>
                <li
                  :style="'margin-left: 0px !important'"
                  v-for="choice in field.choices"
                  :key="choice"
                >
                  <label
                    :for="choice"
                    :key="choice"
                    :class="
                      values[field.key] && values[field.key].includes(choice)
                        ? 'checkedMulticheck multicheck'
                        : 'multicheck'
                    "
                    @click="
                      () => {
                        values[field.key] = addOrRemove(
                          values[field.key],
                          choice
                        );

                        if (values[field.key].length === 0) {
                          delete values[field.key];
                        }
                      }
                    "
                  >
                    <input
                      class="hiddenCheckbox"
                      v-if="field.type === 'checkbox'"
                      :required="field.required === 0 ? false : true"
                      type="checkbox"
                      id="name-id"
                      :name="field.key"
                      maxlength="60"
                      :placeholder="field.placeholder"
                      v-model="values[field.key]"
                    />
                    {{ choice }}
                  </label>
                </li>
              </ul>
            </template>

            <template
              v-if="
                field.type === 'radio' &&
                Object.keys(field.choices).length === 1
              "
            >
              <div
                class="row"
                :style="'margin-left: 0px !important'"
                v-for="(choice, key) in field.choices"
                :key="choice"
              >
                <div
                  type="radio"
                  id="name-id"
                  class="col-sm-2 checkboxdiv"
                  :name="field.key"
                  maxlength="60"
                  :placeholder="field.placeholder"
                  @click="inputRadio(field, key)"
                >
                  <div
                    :class="
                      values[field.key] && values[field.key].includes(choice)
                        ? 'checked'
                        : ''
                    "
                  ></div>
                </div>
                <input
                  style="
                    height: 0px;
                    width: 0px;
                    padding: 0px;
                    margin: 0px;
                    border: 0px !important;
                    position: absolute;
                  "
                  v-if="field.type === 'radio'"
                  :required="field.required === 0 ? false : true"
                  type="radio"
                  :name="field.key"
                  maxlength="60"
                  :id="choice"
                  :value="choice"
                  :placeholder="field.placeholder"
                  v-model="values[field.key]"
                />
                <label :for="choice" v-html="choice" class="col-sm-10"></label>
              </div>
            </template>

            <template
              v-if="
                field.type === 'radio' && Object.keys(field.choices).length > 1
              "
            >
              <GreenifiedRadio
                :showError="
                  field.required === 1 &&
                  showValidation &&
                  (values[field.key] ?? '') === ''
                "
                :required="field.required === 1"
                :choices="field.choices"
                :value="values[field.key]"
                @click="(key) => inputRadio(field, key)"
              >
              </GreenifiedRadio>
            </template>
            <template v-if="field.name === 'varumarke'">
              <div>
                <GrDropdown
                  ref="vueSelect"
                  :small="true"
                  :options="getProjectSuppliers"
                  label="varumarke"
                  :key="values[field.key]"
                  :value="values[field.key]"
                  @update:model-value="inputSupplier($event, field)"
                  :clearable="false"
                  :placeholder="$t('placeholders.brand')"
                  :show-error="
                    (!values[field.key] ||
                      !values[field.name] ||
                      !other_values.supplier_code) &&
                    showValidation
                  "
                  :error-text="$t('label.fieldRequired')"
                >
                  <template #list-header>
                    <div
                      class="d-flex ms-3 align-items-center add-supplier"
                      @click="
                        () => {
                          $emit('showAddSupplier', field.key);
                          $refs.vueSelect[0].open = false;
                          document.body.style.zoom = 1.0;
                        }
                      "
                    >
                      <IconButton :img="'plus.svg'" width="24" height="24" />
                      <p class="m-0 p-0 ms-2">{{ $t("button.add") }}</p>
                    </div>
                  </template>
                </GrDropdown>
              </div>
              <input
                required="true"
                :name="field.key"
                v-model="values[field.key]"
                style="
                  height: 0px;
                  width: 0px;
                  padding: 0px;
                  margin: 0px;
                  border: 0px !important;
                  position: absolute;
                "
              />
            </template>
            <GrDropdown
              v-if="field.field_type === 'select' || field.type === 'select'"
              :required="field.required == 1 ? true : false"
              :show-error="!values[field.key] && showValidation"
              :id="field.key"
              :error-text="$t('label.fieldRequired')"
              :name="field.key"
              :placeholder="field.placeholder"
              v-model="values[field.key]"
              @update:model-value="changeSelect(field, $event)"
              :label="'namn'"
              :reduce="'id'"
              :options="
                field.type === 'acfe_taxonomy_terms'
                  ? $store.getters.project_settings[field.taxonomy[0]].filter(
                      (option) =>
                        ($store.getters.project_settings.sprak === 'fi' &&
                          field.allow_terms.includes(option.sv_id) &&
                          option.locale === 'fi') ||
                        ($store.getters.project_settings.sprak === 'da' &&
                          field.allow_terms.includes(option.sv_id) &&
                          option.locale === 'da') ||
                        (field.allow_terms.includes(option.id) &&
                          !['fi', 'da'].includes(
                            $store.getters.project_settings.sprak
                          ))
                    )
                  : choicesToArray(field.choices)
              "
            >
            </GrDropdown>
          </div>
        </FormRow>
      </div>

      <div
        v-if="
          field.name === 'pris' &&
          (values[field.key] ?? '') !== '' &&
          field.type !== 'group'
        "
      >
        <div class="acf-label col-sm-4">
          <label class="mt-2" :for="field.key">{{
            $t("label.salesPriceOnGreenified")
          }}</label>
        </div>
        <div class="acf-input col-sm-8">
          <div class="acf-input-wrap fw-bold mt-2">
            {{ getCurrencySymbol(calcPrice) }}
          </div>
        </div>
      </div>
    </template>
  </template>
</template>

<script>
import FormGroup from "@/shared/components/FormGroup.vue";
import FormRow from "@/shared/components/FormRow.vue";
import GreenifiedRadio from "@/shared/components/GreenifiedRadio.vue";
import InputField from "@/shared/components/InputField.vue";
import ImageUploader from "./ImageUploader.vue";
import IconButton from "@/shared/components/IconButton.vue";
import checkbox from "@/shared/components/Checkbox.vue";
import GrDropdown from "@/shared/components/GrDropdown.vue";

export default {
  name: "ItemDescription",
  props: {
    in_values: Object,
    struc: Array,
    in_other_values: Object,
    showValidation: Boolean,
    priceSuggestion: { type: Array, default: () => [] },
    calcPrice: { type: Number, default: null },
    emit: { type: Array, default: () => [] },
    include: { type: Array, default: null },
  },
  emits: [
    "update:in_values",
    "update:in_other_values",
    "priceChange",
    "showAddSupplier",
    "allRefs",
  ],

  components: {
    FormGroup,
    FormRow,
    GreenifiedRadio,
    InputField,
    ImageUploader,
    IconButton,
    checkbox,
    GrDropdown,
  },
  data() {
    return {
      allRefs: {},
      document: document,
      values: null,
      findMessage: 'href="https://vimeo.com/',
      replaceMessage: 'target="_blank" href="https://player.vimeo.com/video/',
      other_values: null,
    };
  },

  methods: {
    getCurrentRefs() {
      return this.$refs;
    },
    getAllRefs() {
      for (const key in this.getCurrentRefs()) {
        if (key.includes("groupfield")) {
          let ref = this.getCurrentRefs()[key][0]?.getCurrentRefs();
          this.emitAllRefs(ref);
        }
      }
    },
    emitAllRefs(refs) {
      for (const key in refs) {
        if (!key.includes("vueSelect") && refs[key].length) {
          this.allRefs[key] = refs[key];
        }
      }
      return this.$emit("allRefs", this.allRefs);
    },

    andOperator(and_array) {
      let cond;
      for (let j = 0; j < and_array.length; j++) {
        cond = and_array[j];
        if (cond.operator === "==empty" && this.values[cond.field] != null) {
          return false;
        } else if (
          cond.operator === "!=empty" &&
          this.values[cond.field] == null
        ) {
          return false;
        } else if (
          !cond.operator.includes("empty") &&
          eval(
            '"' +
              String(this.values[cond.field]) +
              '"' +
              cond.operator +
              ('"' + String(cond["value"]) + '"')
          ) === false
        ) {
          return false;
        }
      }
      return true;
    },

    removeSubfieldValues(subFields) {
      subFields.forEach((subField) => {
        delete this.values[subField.key];

        if (subField.sub_fields && subField.sub_fields.length > 0) {
          this.removeSubfieldValues(subField.sub_fields);
        }
      });
    },

    checkConditions(field) {
      let conds = field.conditional_logic;

      if (conds === 0) {
        return true;
      }
      let cond2;
      let res;
      for (let i = 0; i < conds.length; i++) {
        cond2 = conds[i];
        res = this.andOperator(cond2);
        if (res) {
          return true;
        }
      }

      delete this.values[field.key];

      if (field.sub_fields && field.sub_fields.length > 0) {
        this.removeSubfieldValues(field.sub_fields);
      }

      return false;
    },
    choicesToArray(obj) {
      let array = [];
      for (let key in obj) {
        array.push({ id: key, namn: obj[key] });
      }
      return array;
    },

    addOrRemove(array, value) {
      if (array === undefined) {
        array = [];
      }
      var index = array.indexOf(value);

      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
      return array;
    },
    inputSupplier(option, field) {
      this.values[field.key] = option.varumarke;
      this.other_values.supplier_code = option.levkod;
    },
    inputRadio(field, key) {
      this.values[field.key] = key;
      if (field.name === "skick") {
        this.other_values.skick2 = key;
      }
    },
    inputText(option, field) {
      this.values[field.key] = option.target.value;

      if (field.name === "produktnamn") {
        this.other_values.benamn = option.target.value;
      }
    },
    inputCheckbox(option, field, yes, no) {
      if (option.target.value === "on" || option.target.value === String(no)) {
        this.values[field.key] = yes;
      } else {
        delete this.values[field.key];
      }

      if (field.name === "produktnamn") {
        this.other_values.benamn = option.target.value;
      }
    },
    changeSelect(field, e) {
      this.values[field.key] = e;
      if (field.name === "kategori") {
        this.other_values.kategori = e;
      }
    },
    getCurrencySymbol(amount) {
      return parseInt(amount).toLocaleString(this.$i18n.locale, {
        style: "currency",
        currency: this.$store.getters.projectDetails.valuta,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    },
  },
  mounted() {
    this.values = this.in_values;
    this.other_values = this.in_other_values;

    for (const field of this.struc) {
      if (
        field.name === "produktionsar" &&
        !this.values[field.key] &&
        field.default_value &&
        (this.include
          ? this.include.includes(field.name)
          : !this.emit.includes(field.name))
      ) {
        this.values[field.key] = field.default_value;
      }
    }
  },

  computed: {
    getProjectSuppliers() {
      return (this.$store.getters.project_settings.lev ?? []).map((obj) => {
        obj.varumarke = obj.varumarke.includes("Extraleverantör")
          ? this.$t("label.unkownSupplier")
          : obj.varumarke;
        return obj;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.add-supplier {
  height: 44px;
}

h3 {
  margin: 40px 0 0;
}

a {
  color: #42b983;
}
.acf-field {
  margin-bottom: 1rem;
}

.fitRow {
  width: fit-content;
}
.img-container {
  background-color: white;
  border: 1.5px solid #000;
  min-height: 43px;
  display: flex;
  position: relative;
  align-items: center;
}
.img-label-wrapper {
  display: flex;
  text-align: center;
  flex-direction: row;
}
.navbar-toggle-inner {
  z-index: 5 !important;
  position: absolute;
  top: 0px;
  right: 0px;
}
.navbar-toggle-inner:hover span {
  background-color: rgba(0, 0, 0, 0.5);
}
.checkboxlabel {
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.52px;
}
.checked {
  content: "";
  position: absolute;
  left: 16px;
  top: -2px;
  width: 13px;
  height: 25px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  top: 10%;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.selectFileLabel {
  font-weight: bold;

  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
input + .vs__dropdown-menu {
  visibility: hidden;
  transition-delay: 250ms;
  transition-property: visibility;
}
input:focus + .vs__dropdown-menu {
  visibility: visible;
}
ul {
  list-style-type: none;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  margin-bottom: 0px;
  margin-right: -10px;
  & li {
    display: inline-block;
    margin: 0 10px;
    flex-grow: 1;
  }
}
input[type="checkbox"].hiddenCheckbox {
  height: 0px;
  width: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:before {
    background: none;
    height: 0px;
    width: 0px;
  }
}
</style>
