<template>
  <div v-if="!loading">
    <LoadingOverlay v-if="loadingPost"></LoadingOverlay>
    <CustomForm
      ref="form"
      validateObject
      :objectToValidate="data_item.green_struc"
      @isValid="
        (validFields) => {
          showValidation = !validFields;
        }
      "
      @submit="
        () => {
          if (productsToScan.length > 1) {
            verifyProducts();
          } else {
            showSave = true;
          }
        }
      "
    >
      <template #content>
        <FormGroup>
          <FormRow :label="$t('header.location') + ' *'">
            <div class="col-12">
              <div v-if="!data_item?.location" ref="location"></div>
              <GrDropdown
                :room="true"
                :small="true"
                :options="$store.getters.project_settings.alla_rum ?? []"
                :clearable="false"
                :key="data_item.location"
                :showError="showValidation && data_item.location === null"
                :errorText="$t('label.fieldRequired')"
                :placeholder="$t('placeholders.selectLocation')"
                label="rumsnamn"
                :reduce="'rum'"
                v-model="data_item.location"
                :filterBy="
                  (option, label, search) => {
                    return (
                      extendedFilter(search, option.rumsnamn) ||
                      extendedFilter(search, option.rumskod) ||
                      extendedFilter(search, String(option.vaning)) ||
                      extendedFilter(search, option.byggnad)
                    );
                  }
                "
              >
                <template #option="option">
                  <div class="row w-100 justify-content-between">
                    <div class="col-12 d-flex flex-wrap align-items-center">
                      <div
                        class="option-border-t"
                        v-if="
                          option.option.rum !== data_item.location &&
                          getRoomIndex(data_item.location) !==
                            option.option.index - 1
                        "
                      ></div>
                      <p class="fw-bolder me-3 p-0">
                        {{ option.option.rumsnamn }}
                      </p>
                      <p v-if="option.option.rumskod" class="me-3 p-0">
                        {{ option.option.rumskod }}
                      </p>
                      <p v-if="option.option.vaning" class="fw-bolder me-3 p-0">
                        {{ $t("label.floorShortened") }}
                        {{ option.option.vaning }}
                      </p>
                      <p class="p-0" v-if="option.option.byggnad">
                        {{ option.option.byggnad }}
                      </p>

                      <div
                        class="option-border-b"
                        v-if="
                          option.option.index ===
                            $store.getters.project_settings.alla_rum.length -
                              1 && option.option.rum !== data_item.location
                        "
                      ></div>
                    </div>
                  </div>
                </template>
              </GrDropdown>
            </div>
          </FormRow>
        </FormGroup>

        <itemDesc
          v-show="typeof data_item.green_struc === 'object'"
          @allRefs="
            (refs) => {
              let currentRefs = {};
              for (const key in refs) {
                if (
                  !data_item.green_struc[key] &&
                  Object.keys(refs[key]).length
                ) {
                  currentRefs[key] = refs[key];
                }
              }
              if (!data_item.location) {
                currentRefs = { location: [$refs.location] };
              }
              $refs.form.validateRefObject(currentRefs);
            }
          "
          v-model:in_values="data_item.green_struc"
          v-model:in_other_values="data_item"
          ref="mainItemDesc"
          :key="data_item.green_struc"
          :showValidation="showValidation"
          :struc="struc"
          :emit="[
            'miljomarkningar',
            'intro',
            'internt',
            'butiksägare',
            'skick_super',
            'antal',
            'saljs_styckvis_eller_i_grupp',
            'ange-antal',
            'pris',
            'emballage',
            'packaging',
            'notering',
            'product_labels_for_admin',
            'bild_5',
          ]"
          @showAddSupplier="
            (field) => {
              showAddSupplier = true;
              supplierField = field;
            }
          "
        ></itemDesc>
        <form-group v-if="!registerNewProduct">
          <form-row :label="`${$t('tableHeaders.quantity')} *`">
            <InputField
              width="small"
              :value="data_item?.same_products?.length"
              :key="data_item?.same_products?.length"
              type="number"
              :disabled="true"
              :min="1"
              class="w-100"
            />
          </form-row>
          <form-title
            :paragraph="$t('paragraph.removeProductInformation')"
          ></form-title>
          <form-row>
            <GreenifiedButton
              @clicked="
                clickedAddMoreProducts = true;
                newLocationForExtraProduct = data_item.location;
              "
              :text="$t('button.addMoreProducts')"
              type="white"
              :noWrap="true"
              longText
            ></GreenifiedButton>
          </form-row>
        </form-group>
        <form-group v-else>
          <form-row :label="`${$t('tableHeaders.quantity')} *`">
            <div class="row">
              <div class="col-5">
                <InputField
                  v-model="addCountNewProducts"
                  type="number"
                  :min="1"
                  :max="999"
                />
              </div>
              <div class="col-7">
                <GreenifiedButton
                  style="width: auto"
                  type="black"
                  :disabled="addCountNewProducts <= 1"
                  @click="
                    preBluredQuantity = addCountNewProducts;
                    addCountExtraProducts = addCountNewProducts;
                    this.openCameraComponent(preBluredQuantity);
                  "
                  :text="$t('button.continue')"
                />
              </div>
            </div>
          </form-row>
        </form-group>
        <form-group v-if="clickedAddMoreProducts && !registerNewProduct">
          <form-title
            :title="$t('menuTitle.addMoreProducts')"
            :paragraph="$t('paragraph.addProductInformation')"
          ></form-title>
          <form-row :label="`${$t('tableHeaders.quantity')} *`">
            <div class="row">
              <div class="col-5">
                <InputField
                  v-model="preBluredQuantity"
                  type="number"
                  :min="1"
                  :max="999"
                />
              </div>

              <div class="col-7">
                <GreenifiedButton
                  @clicked="whenBlurAddExtraProducts()"
                  :text="$t('button.continue')"
                  :disabled="!preBluredQuantity"
                  :type="'black'"
                />
              </div>
            </div>
          </form-row>
          <form-row :label="`${$t('placeholders.room')} *`">
            <div class="col-12">
              <GrDropdown
                :options="$store.getters.project_settings.alla_rum ?? []"
                label="rumsnamn"
                :reduce="'rum'"
                :clearable="false"
                :showError="
                  showValidation && this.data_item.new_location === null
                "
                v-model="data_item.new_location"
              />
            </div>
          </form-row>
        </form-group>
        <div ref="newProducts"></div>
        <div
          v-if="
            registerNewProduct ||
            productsToScan.find(
              (product) => 'comp_id' in product && product?.comp_id !== null
            )
          "
        >
          <form-group
            style="gap: 10px"
            v-if="
              registerNewProduct ||
              (!registerNewProduct && clickedAddMoreProducts)
            "
            :key="preBluredQuantity"
          >
            <form-title
              :title="
                productsToScan.length === 1 && newProduct
                  ? $t('tableHeaders.extraInfo')
                  : $t('tableHeaders.scannedProducts')
              "
              :paragraph="$t('paragraph.addInformationNoScan')"
            />

            <form-row
              :noLabel="true"
              v-for="(newProduct, i) in productsToScan"
              :key="newProduct"
              :align-center="true"
            >
              <InputField
                class=""
                size="small"
                :not-editable="true"
                :placeholder="`${$t('label.product')} ` + (i + 1)"
                :modelValue="newProduct.comp_id"
              />

              <IconButton
                :width="24"
                :height="24"
                @click="openNewProductEdit(i)"
                class="ms-4"
                :disabled="!newProduct.comp_id"
                :img="
                  newProduct.comp_id
                    ? edited(newProduct)
                      ? 'edit_pen.svg'
                      : 'plus.svg'
                    : 'plus-disabled.svg'
                "
              ></IconButton>
            </form-row>
          </form-group>
        </div>

        <form-group v-if="!registerNewProduct">
          <form-title
            :title="$t('tableHeaders.previouslyScannedProducts')"
            :paragraph="$t('paragraph.addInformation')"
          >
          </form-title>
          <template
            v-for="(product, i) in data_item.same_products"
            :key="product"
          >
            <form-row
              :noLabel="true"
              :align-center="true"
              v-if="product.delete_type === undefined && i < productLimit"
            >
              <InputField
                class=""
                size="small"
                :notEditable="true"
                :value="product.comp_id"
                :bold="product.comp_id === data_item.comp_id"
              />

              <IconButton
                :width="24"
                :height="24"
                class="ms-4"
                @click="openSameProductEdit(i)"
                :img="edited(product) ? 'edit_pen.svg' : 'plus.svg'"
              ></IconButton
              ><GreenifiedButton
                textSize="btn-text-medium"
                :noWrap="true"
                @clicked="removeProductClicked(i)"
                :text="$t('button.remove')"
                type="transparent"
              ></GreenifiedButton>
            </form-row>
          </template>
          <FormRow
            @click="productLimit *= 2"
            noLabel
            v-if="data_item.same_products && data_item.same_products.length > 6"
          >
            <div class="d-flex align-items-center ps-0">
              <IconButton
                noWrapper
                :width="24"
                :height="24"
                :img="'plus.svg'"
              />
              <p class="ps-2 m-0 cursor-default">
                {{ $t("label.showMoreScannedProducts") }}
              </p>
            </div>
          </FormRow>
        </form-group>
        <FormGroup noGap>
          <FormTitle :title="$t('label.otherInformation')" />
          <FormRow :noLabel="true" :align-center="true">
            <InputTextField
              v-model="otherInformation"
              @update:model-value="
                (value) => (data_item.green_struc.other_information = value)
              "
            />
          </FormRow>
        </FormGroup>
        <span
          style="
            color: #dc3545;
            text-align: center;
            width: 100%;
            display: block;
          "
        >
          {{
            showValidation && !showSave && !loadingPost
              ? $t("label.validationNotPassed")
              : "&nbsp;"
          }}</span
        >
        <div class="d-flex justify-content-center">
          <div class="col-12 col-sm-10 col-md-8 col-lg-6 px-4 pb-4">
            <GreenifiedButton
              type="black"
              @click="
                () => {
                  fetchProductRefs(struc);
                }
              "
              :text="
                registerNewProduct
                  ? $t('button.createProduct')
                  : $t('button.save')
              "
            />
          </div>
        </div>
      </template>
    </CustomForm>

    <VerificationModal
      v-if="showAddSupplier"
      @close="
        showAddSupplier = false;
        supplierField = null;
      "
      :singleButton="true"
      position="top"
      :confirmButtonText="$t('button.close')"
      :title="$t('label.addSupplier')"
      :text="'null'"
    >
      <template #text>
        <AddProjectSupplierContent
          @added="
            (addedSupplier) => {
              showAddSupplier = false;
              data_item['supplier_code'] = addedSupplier.levkod;
              data_item.green_struc.varumarke = addedSupplier.varumarke;
              data_item.green_struc[supplierField] = addedSupplier.varumarke;
              supplierField = null;
            }
          "
        />
      </template>
    </VerificationModal>

    <VerificationModal
      v-if="removeIndex !== null"
      @close="removeIndex = null"
      :title="
        $t('verificationTitles.removeProductOption', [
          data_item.same_products[removeIndex].comp_id,
        ])
      "
    >
      <template #content>
        <div class="row">
          <div class="col text-tiny">
            {{ $t("verificationTexts.removeLabelInformation") }}
          </div>
        </div>
        <GreenifiedButton
          type="black"
          class="mt-4"
          size="medium"
          textSize="btn-text-medium"
          @click="
            data_item.same_products[removeIndex].delete_type = 1;

            showClearResponse = true;
          "
          :noWrap="true"
          :text="$t('button.removeLabel')"
        ></GreenifiedButton>
        <div class="row mt-4">
          <div class="col text-tiny">
            {{ $t("verificationTexts.verifyRemoveProduct") }}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-10">
            <GreenifiedButton
              type="black"
              textSize="btn-text-medium"
              @click="
                data_item.same_products[removeIndex].delete_type = 2;
                showDeleteResponse = true;
              "
              :noWrap="true"
              longText
              :text="$t('button.removeProduct')"
            ></GreenifiedButton>
          </div>
        </div>
      </template>
    </VerificationModal>
  </div>
  <VerificationModal
    v-if="showDeleteResponse || showClearResponse"
    singleButton
    :title="$t('verificationTitles.changeHasBeenRegistered')"
    :text="
      showDeleteResponse
        ? $t('verificationTexts.deleteProductResponse', [
            bolderFont(data_item.same_products[removeIndex].comp_id),
          ])
        : $t('verificationTexts.clearProductResponse', [
            bolderFont(data_item.same_products[removeIndex].comp_id),
          ])
    "
    @close="
      showDeleteResponse = false;
      showClearResponse = false;
      removeIndex = null;
    "
  />
  <VerificationModal
    v-if="showSave"
    :text="$t('label.areYouSureSave')"
    :title="$t('label.confirmation')"
    @confirm="postItem()"
    @close="showSave = false"
  />
  <VerificationModal
    v-if="showInvalid"
    @confirm="postItem()"
    @close="showInvalid = false"
    :title="$t('verificationTitles.unscannedProducts')"
    :text="
      $t('verificationTexts.verifyUnscannedProducts', [invalidProducts.length])
    "
  />
  <VerificationModal v-if="verifyDeleteProduct" />
</template>
<script>
import itemDesc from "../components/ItemDescription";
import item_api from "@/item/services/api/item";
import LoadingOverlay from "@/shared/components/LoadingOverlay";
import VerificationModal from "@/shared/modals/VerificationModal.vue";
import AddProjectSupplierContent from "./AddProjectSupplierContent.vue";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import FormRow from "@/shared/components/FormRow";
import FormGroup from "@/shared/components/FormGroup";
import FormTitle from "@/shared/components/FormTitle";
import IconButton from "@/shared/components/IconButton.vue";
import InputField from "@/shared/components/InputField.vue";
import CustomForm from "@/shared/components/CustomForm.vue";
import GrDropdown from "@/shared/components/GrDropdown.vue";
import InputTextField from "@/shared/components/InputTextField.vue";

export default {
  name: "itemComponent",
  props: {
    item: { type: Object, default: () => {} },
    productsToScan: { type: Array, default: () => [{}] },
    newProduct: { type: Boolean, default: false },
  },
  emits: [
    "openCameraComponent",
    "openSameProductEdit",
    "openNewProductEdit",
    "update:productsToScan",
    "scrollTo",
  ],
  data() {
    return {
      showDeleteResponse: false,
      showClearResponse: false,
      otherInformation: "",
      supplierField: null,
      data_item: {},
      loading: false,
      showSave: false,
      showInvalid: false,
      showValidation: false,
      showAddSupplier: false,
      loadingPost: false,
      clickedAddMoreProducts: false,
      addCountNewProducts: 1,
      newLocationForExtraProduct: null,
      preBluredQuantity: 1,
      componentExtraProducts: this.productsToScan,
      removeIndex: null,
      invalidProducts: [],
      productLimit: 6,
    };
  },
  components: {
    itemDesc,
    LoadingOverlay,
    FormRow,
    FormGroup,
    VerificationModal,
    AddProjectSupplierContent,
    GreenifiedButton,
    IconButton,
    InputField,
    FormTitle,
    CustomForm,
    GrDropdown,
    InputTextField,
  },

  computed: {
    struc() {
      return this.$store.getters.struc;
    },
    registerNewProduct() {
      return this.newProduct;
    },
    addCountExtraProducts: {
      get: function () {
        return this.componentExtraProducts.length;
      },
      set: function (newValue) {
        let localVlaue = newValue;
        if (localVlaue > 999) {
          localVlaue = 999;
        }
        if (localVlaue < 1) {
          localVlaue = 1;
        }
        if (this.componentExtraProducts.length < localVlaue) {
          for (
            let i = this.componentExtraProducts.length;
            i < localVlaue;
            i++
          ) {
            this.componentExtraProducts.push({});
          }
        } else if (this.componentExtraProducts.length > localVlaue) {
          this.componentExtraProducts = this.componentExtraProducts.slice(
            0,
            localVlaue
          );
        }
        this.$emit("update:productsToScan", this.componentExtraProducts);
      },
    },
  },
  mounted() {
    this.data_item = this.item;
  },
  methods: {
    removeSubfieldValues(subFields) {
      subFields.forEach((subField) => {
        delete this.data_item.green_struc[subField.key];

        if (subField.sub_fields && subField.sub_fields.length > 0) {
          this.removeSubfieldValues(subField.sub_fields);
        }
      });
    },
    fetchProductRefs(struc) {
      this.updateFieldNames(struc);
      this.$refs.mainItemDesc.getAllRefs();
    },
    updateFieldNames(struc) {
      struc.forEach((field) => {
        const fieldMeetsConditions =
          this.$refs.mainItemDesc.checkConditions(field);

        if (field.sub_fields && field.sub_fields.length > 0) {
          if (!fieldMeetsConditions) {
            this.removeSubfieldValues(field.sub_fields);
          }

          this.updateFieldNames(field.sub_fields);
        }
      });
    },

    bolderFont(text) {
      return `<b>${text}</b>`;
    },

    extendedFilter(searchValue, searchKey) {
      return (searchKey || "")
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    },
    getRoomIndex(selectedRoom) {
      return this.$store.getters.project_settings.alla_rum.findIndex(
        (room) => room.rum === selectedRoom
      );
    },
    verifyProducts() {
      this.invalidProducts = this.productsToScan.filter(
        (product) => !("comp_id" in product) || product?.comp_id === null
      );

      if (this.invalidProducts.length) {
        this.showInvalid = true;
      } else {
        this.showSave = true;
      }
    },
    scrollToContainer() {
      window.setTimeout(() => {
        const element = this.$refs.newProducts;
        window.scrollTo({
          top: element.offsetTop - 400,
          left: 0,
          behavior: "instant",
        });
      }, 100);
    },
    openSameProductEdit(i) {
      this.$emit("openSameProductEdit", i);
    },
    openNewProductEdit(i) {
      let position = window.scrollY;
      this.$emit("scrollTo", position);
      this.$emit("openNewProductEdit", i);
    },
    whenBlurAddExtraProducts() {
      this.addCountExtraProducts = this.preBluredQuantity;
      this.openCameraComponent(this.preBluredQuantity);
    },

    removeProductClicked(i) {
      this.removeIndex = i;
    },
    edited(product) {
      if (
        product.damages !== null ||
        Object.keys(product.productSpecifics).length !== 0 ||
        product.productSpecificImages.filter((elem) => elem !== null).length > 0
      ) {
        return true;
      }
      return false;
    },
    openCameraComponent() {
      this.$emit("openCameraComponent", this.componentExtraProducts);
    },

    postItem() {
      this.loadingPost = true;
      this.data_item.new_products = this.productsToScan;

      item_api
        .postItem(this.data_item.id, this.data_item)
        .then(async (response) => {
          let historyRow = response.data;
          historyRow["location"] = this.data_item.location;
          this.$store.dispatch("addToHistory", response.data);
          this.loadingPost = false;

          await this.$router.push({
            name: "saved",
            params: { id: this.data_item.id },
            query: {
              new: this.registerNewProduct,
              comp_id: this.data_item.comp_id,
            },
          });
          this.showSave = false;
        })
        .catch((e) => {
          this.loadingPost = false;
          this.showSave = false;
          this.$httpError(e, "ItemComponent - postItem");
        });
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.acf-field {
  margin-bottom: 1rem;
}
.row {
  margin-right: 0px;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.inner-container {
  width: 100%;
  background-color: #f7f5ee;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.modal-text {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.52px;
}
</style>
