<template>
  <div class="accordion-wrapper" :class="{ visible: showContent }">
    <div
      class="accordion-content"
      :class="{ expanded: showContent }"
      ref="contentRef"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggleAccordion: { type: Boolean, default: false },
  },
  data() {
    return {
      showContent: this.toggleAccordion,
    };
  },
  watch: {
    toggleAccordion(expanded) {
      if (expanded !== this.showContent) {
        this.$nextTick(() => {
          const contentRef = this.$refs.contentRef;
          if (contentRef) {
            this.showContent = expanded;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.accordion-wrapper {
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.accordion-content.expanded {
  max-height: 4000px;
  transition: max-height 1s;
}
</style>
