<template>
  <GreenifiedCard :hidePadding="'top bottom'">
    <template #cardContent>
      <slot name="content">
        <div class="row align-items-center" v-if="!showId">
          <div
            class="col d-flex align-items-center breadcrumb-wrapper emphasis text-tiny"
          >
            <div
              class="d-flex align-items-center"
              v-if="breadCrumbs.mainCategory"
            >
              <p>
                {{ breadCrumbs.mainCategory }}
              </p>
              <p class="ms-1 me-1">/</p>
            </div>
            <div
              class="d-flex align-items-center"
              v-if="breadCrumbs.subCategory"
            >
              <p>
                {{ breadCrumbs.subCategory }}
              </p>
              <p class="ms-1 me-1">/</p>
            </div>
            <p>
              {{ breadCrumbs.articleNumber }}
            </p>
          </div>
        </div>
        <div
          class="row align-items-center breadcrumb-wrapper emphasis text-tiny"
          v-else
        >
          <div class="col-12 d-flex justify-content-between">
            <p>
              {{ breadCrumbs.articleNumber }}
            </p>

            <p>ID: {{ id }}</p>
          </div>
        </div>
      </slot>
    </template>
  </GreenifiedCard>
</template>

<script>
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";

export default {
  components: { GreenifiedCard },
  props: {
    breadCrumbs: { type: [Array, Object], default: null },
    showId: { type: Boolean, default: false },
    id: { type: [String, Number], default: null },
  },
  emits: ["clicked"],
};
</script>

<style scoped>
.breadcrumb-wrapper {
  height: 60px;
}
</style>
