<template>
  <LoadingOverlay v-if="loading" />
  <SearchField
    type="text"
    :gr-color="false"
    :placeHolder="$t('button.search')"
    :focus="true"
    @input="debounceInput"
  />
  <div class="supplier-list container-fluid">
    <template
      v-for="(supplier, i) in this.searchRes[this.searchValue]"
      :key="supplier"
    >
      <div class="row supplier-row">
        <div class="col">
          {{ $t(supplier.varumarke) }}
        </div>
        <div class="col">
          <GreenifiedButton
            @click="addSupplier(this.searchRes[this.searchValue][i])"
            type="black"
            :text="
              supplier.status === 1
                ? $t('button.loading')
                : supplier.status === 2
                ? $t('button.added')
                : $t('button.add')
            "
            :disabled="supplier.status"
          >
          </GreenifiedButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import debounce from "debounce";
import item_api from "@/item/services/api/item";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import SearchField from "@/shared/components/SearchField.vue";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";

export default {
  data() {
    return {
      searchValue: "",
      searchRes: { "": [] },
      loadingSearch: false,
      loading: false,
    };
  },
  computed: {},
  emits: ["added"],
  methods: {
    addSupplier(supplier) {
      this.loading = true;
      supplier["status"] = 1;
      item_api
        .addSupplier(supplier.levkod, this.$store.getters.project_nr)
        .then((response) => {
          supplier["status"] = 2;
          this.$store.dispatch("setProjectSettingsSuppliers", response.data);
          this.loading = false;
          let addedSupplier = response.data.find(
            (supp) => supp.levkod === supplier.levkod
          );
          this.$emit("added", addedSupplier);
        })
        .catch((e) => {
          this.$httpError(e, "AddProjectSupplierContent - addSupplier");
        });
    },
    debounceInput: debounce(function (e) {
      this.searchValue = e.target.value;
      if (!(e.target.value in this.searchRes) && e.target.value !== "") {
        this.loadingSearch = 2;
        item_api
          .getSuppliers(e.target.value)
          .then((response) => {
            this.searchRes[e.target.value] = response.data;
            this.loadingSearch = false;
          })
          .catch((e) => {
            this.$httpError(e, "AddProjectSupplierContent - getSuppliers");
          });
      }
    }, 500),
  },
  components: { GreenifiedButton, SearchField, LoadingOverlay },
};
</script>

<style scoped>
.supplier-row {
  font-weight: 500;
  display: flex;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  align-items: center;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.supplier-list {
  margin-top: 1rem;
  max-height: 50vh;
  overflow-y: auto;
}
</style>
