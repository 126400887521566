<template>
  <form
    @submit.prevent="validateObject ? validateRefObject() : validateForm()"
    :id="formId"
  >
    <slot name="content" />
  </form>
</template>

<script>
export default {
  emits: ["isValid", "submit"],
  data() {
    return {
      invalidField: null,
    };
  },
  props: {
    formId: { type: String, default: "custom-form" },
    refs: { type: Object, default: null },
    validateObject: { type: Boolean, default: false },
    objectToValidate: { type: Object, default: null },
  },

  methods: {
    validateRefObject(refs) {
      const fullHeight = document.body.scrollHeight;
      const refArray = Object.entries(refs);
      const sortedArray = refArray.sort((a, b) => {
        const aTop = a[1][0]?.getBoundingClientRect().top;
        const bTop = b[1][0]?.getBoundingClientRect().top;
        return aTop - bTop;
      });

      for (let i = 0; i < sortedArray.length; i++) {
        let ref = sortedArray[i];
        let validField = this.objectToValidate[ref[0]];

        if (!validField) {
          this.invalidField = ref[1][0];
          let position =
            this.invalidField.getBoundingClientRect().top + fullHeight;
          let reducedHeight = screen.width > 500 ? 1200 : 1000;

          window.scrollTo({
            top: position - reducedHeight,
          });
          this.$emit("isValid", false);
          return;
        }
      }

      this.$emit("isValid", true);
      this.$emit("submit");
    },
    validateForm() {
      const fullHeight = document.body.scrollHeight;
      for (const key in this.refs) {
        if (key.includes("true") && this.refs[key] !== null) {
          this.invalidField = this.refs[key][0];
          let position =
            this.invalidField.getBoundingClientRect().top + fullHeight;
          let reducedHeight = screen.width > 500 ? 1200 : 1000;

          window.scrollTo({
            top: position - reducedHeight,
          });
          this.$emit("isValid", false);
          return;
        }
      }
      this.$emit("isValid", true);
      this.$emit("submit");
    },
  },
};
</script>
