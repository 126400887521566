<template>
  <div
    class="hamburger-wrapper"
    @click="
      () => {
        $emit('update:modelValue', !modelValue);
      }
    "
  >
    <button
      type="button"
      :class="modelValue ? 'hamburger-toggle open' : 'hamburger-toggle'"
    >
      <span class="closed">Toggle navigation</span>
      <span class="icon-bar bar-1"></span>
      <span class="icon-bar bar-2"></span>
      <span class="icon-bar bar-2-2"></span>
      <span class="icon-bar bar-3"></span>
    </button>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: { modelValue: { type: Boolean, default: false } },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.hamburger-wrapper {
  width: 42px;
  height: 42px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.hamburger-toggle {
  z-index: 20;
  border: none;
  background: 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:active,
  &:focus {
    outline: none;
  }

  .closed {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .icon-bar {
    width: 32px;
    height: 2.2px;
    border-radius: 1px;
    transition: background 0.3s, transform 0.3s;
    position: absolute;

    &.bar-1 {
      top: 10px;
      left: 11px;
      width: 22px;
      transition: all 0.2s;
      background-image: url(~@/assets/img/bar1.svg);
    }

    &.bar-2,
    &.bar-2-2 {
      top: 20px;
      left: 6px;
      transform-origin: center center;
      background-image: url(~@/assets/img/bar2.svg);
    }

    &.bar-3 {
      width: 22px;
      left: 11px;
      top: 30px;
      transition: all 0.2s;
      background-image: url(~@/assets/img/bar3.svg);
    }
  }

  &.open {
    .icon-bar {
      background-color: #000;

      &.bar-1 {
        opacity: 0;
        top: 17px;
      }

      &.bar-2 {
        transform: rotate(-45deg);
      }

      &.bar-2-2 {
        transform: rotate(45deg);
      }

      &.bar-3 {
        opacity: 0;
        top: 17px;
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .hamburger-wrapper {
    display: none;
  }
}
</style>
