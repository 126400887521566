<template>
  <div>
    <FormGroup>
      <FormTitle
        :title="`${$t('tableHeaders.productSpecification')} ` + data.comp_id"
        :paragraph="$t('paragraph.specifiedProductInformation')"
      >
      </FormTitle>
      <FormRow :noLabel="true">
        <InputTextField
          :placeHolder="$t('placeholders.dentDescription')"
          v-model="data.damages"
          :rows="2"
        ></InputTextField
      ></FormRow>
    </FormGroup>
    <ItemDescription
      v-model:in_values="data.productSpecifics"
      v-model:in_other_values="data"
      ref="mainItemDesc"
      :key="data.productSpecifics"
      :struc="struc"
      :include="['info', 'skick']"
    ></ItemDescription>
    <FormGroup>
      <FormTitle :title="$t('tableHeaders.specifiedImages')"> </FormTitle>
      <div class="row justify-content-center">
        <div class="col-6 col-md-5 col-lg-4">
          <ImageUploader
            v-if="data.productSpecificImages[0]"
            :placeholder="`${$t('placeholders.image')} 1`"
            :id="'damages_image_1'"
            v-model="data.productSpecificImages[0].hash"
            @uploaded="null"
          ></ImageUploader>
        </div>
        <div class="col-6 col-md-5 col-lg-4 d-flex justify-content-end">
          <ImageUploader
            v-if="data.productSpecificImages[1]"
            :placeholder="`${$t('placeholders.image')} 2`"
            :id="'damages_image_2'"
            v-model="data.productSpecificImages[1].hash"
            @uploaded="null"
          ></ImageUploader>
        </div>
      </div>
    </FormGroup>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-4 px-4 pb-4">
        <GreenifiedButton
          type="black"
          @clicked="save($)"
          :text="$t('button.save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/shared/components/FormGroup.vue";
import FormRow from "@/shared/components/FormRow.vue";
import FormTitle from "@/shared/components/FormTitle.vue";
import InputTextField from "@/shared/components/InputTextField.vue";
import ItemDescription from "./ItemDescription.vue";
import ImageUploader from "./ImageUploader.vue";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";

export default {
  beforeMount() {
    if (this.data.productSpecificImages === undefined) {
      this.data.productSpecificImages = [];
    }
    for (let i = this.data.productSpecificImages.length; i < 2; i++) {
      this.data.productSpecificImages.push({});
    }
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "instant" });
    if (Array.isArray(this.data.productSpecifics)) {
      this.data.productSpecifics = {};
    }
  },
  emits: ["update:modelValue"],
  components: {
    FormGroup,
    FormTitle,
    FormRow,
    InputTextField,
    ItemDescription,
    ImageUploader,
    GreenifiedButton,
  },
  props: {
    modelValue: { type: Object, default: () => {} },
  },
  data() {
    return { data: this.modelValue };
  },
  methods: {
    save() {
      this.$emit("update:modelValue", this.data);
    },
  },
  computed: {
    struc() {
      return this.$store.getters.struc;
    },
  },
};
</script>

<style lang="scss" scoped></style>
