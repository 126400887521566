<template>
  <PageHeader
    :title="$t('menu.labels')"
    :text="$t('paragraph.labelInformation')"
    :grColor="false"
    v-if="!showOrderLabels"
  >
    <template #headerContent>
      <div class="col-12 mb-3">
        <GreenifiedButton
          :text="$t('pageHeaders.orderLabels')"
          @clicked="showOrderLabels = true"
          size="wide"
        />
      </div>
      <div class="col-12 text-small">
        <p>
          {{ $t("label.labelsTotalQuantity") }}:
          <span class="emphasis">{{ totLabels }}</span>
        </p>
        <p class="mt-1">
          {{ $t("label.orderedLabels") }}:
          <span class="emphasis">{{ orderedLabels }}</span>
        </p>
      </div>
    </template>
  </PageHeader>

  <OrderLabels
    v-if="showOrderLabels"
    @close="showOrderLabels = false"
    @update="
      showOrderLabels = false;
      $store.dispatch('getQuantities');
    "
  />
</template>

<script>
import PageHeader from "@/shared/components/PageHeader.vue";
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import OrderLabels from "../components/OrderLabels.vue";

export default {
  data() {
    return {
      showOrderLabels: false,
    };
  },
  components: {
    PageHeader,
    GreenifiedButton,
    OrderLabels,
  },
  computed: {
    totLabels() {
      return this.$store.getters.quantities["E"];
    },
    orderedLabels() {
      return this.$store.getters.quantities["EO"];
    },
  },
  mounted() {
    this.$store.dispatch("getQuantities");
  },
};
</script>

<style scoped></style>
