<template>
  <div :class="[grColor && 'gr-color']" class="w-100 card-wrapper">
    <div
      class="body-padding greenified-card"
      :class="[hidePadding, thin && 'thin-padding']"
    >
      <div class="container-fluid">
        <div class="row">
          <slot name="cardHeader">
            <p v-if="cardTitle" :class="titleSize">{{ cardTitle }}</p>
          </slot>
        </div>

        <slot name="cardContent" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: { type: String, default: null },
    titleSize: { type: String, default: "heading-small" },
    grColor: { type: Boolean, default: true },
    thin: { type: Boolean, default: false },
    hidePadding: { type: [String, Array], default: "" },
  },
};
</script>

<style scoped>
.card-wrapper {
  max-height: max-content;
}
.greenified-card {
  padding-top: 28px;
  padding-bottom: 42px;
}

.thin-padding {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.bottom {
  padding-bottom: 0 !important;
}

.top {
  padding-top: 0 !important;
}
</style>
