<template>
  <ModalOverlay @click="close()" :bgColor="bgColor">
    <Transition name="fade">
      <div @click.stop class="modal-wrapper gr-color" v-if="showContainer">
        <div class="container-fluid p-0 modal-container">
          <div class="row">
            <div class="col modal-title">
              <slot name="title">
                {{ title }}
              </slot>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col modal-text">
              <slot name="text"> <p v-html="text"></p> </slot>
            </div>
          </div>

          <div
            class="row mt-4 mb-2"
            :class="
              singleButton
                ? 'justify-content-center'
                : 'justify-content-between'
            "
          >
            <slot name="content">
              <div
                class="col-5"
                :class="singleButton && 'd-flex justify-content-center'"
              >
                <GreenifiedButton
                  :btnType="closeButtonType"
                  :size="singleButton ? 'thin' : 'full'"
                  :text="
                    closeButtonText
                      ? closeButtonText
                      : singleButton
                      ? singleCloseText
                      : defaultCloseText
                  "
                  @clicked="close()"
                />
              </div>
              <div class="col-7" v-if="!singleButton">
                <GreenifiedButton
                  :btnType="confirmButtonType"
                  :text="
                    confirmButtonText ? confirmButtonText : defaultConfirmText
                  "
                  noWrap
                  type="black"
                  @clicked="confirm()"
                />
              </div>
            </slot>
          </div>
        </div>
      </div>
    </Transition>
  </ModalOverlay>
</template>

<script>
import GreenifiedButton from "@/shared/components/GreenifiedButton.vue";
import ModalOverlay from "@/shared/modals/ModalOverlay.vue";
export default {
  components: { GreenifiedButton, ModalOverlay },
  data() {
    return {
      defaultCloseText: this.$t("button.cancel"),
      defaultConfirmText: this.$t("button.confirm"),
      singleCloseText: this.$t("button.close"),
      showContainer: false,
    };
  },
  props: {
    singleButton: { type: Boolean, default: false },
    text: { type: String, default: null },
    title: { type: String, default: null },
    confirmButtonText: { type: String, default: null },
    closeButtonText: { type: String, default: null },
    closeOnConfirm: { type: Boolean, default: true },
    confirmButtonType: { type: String, default: "button" },
    closeButtonType: { type: String, default: "button" },
    bgColor: { type: String, default: "black" },
  },
  emits: ["confirm", "close"],
  methods: {
    confirm() {
      this.$emit("confirm");
      if (this.closeOnConfirm) {
        this.close();
      }
    },
    close() {
      this.showContainer = false;

      window.setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
  mounted() {
    document.body.classList.add("modal-open");

    window.setTimeout(() => {
      this.showContainer = true;
    }, 200);
  },
  beforeUnmount() {
    document.body.classList.remove("modal-open");
  },
};
</script>

<style scoped>
.modal-container {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal-wrapper {
  padding: 1.5rem;
  width: auto;
}
</style>
