<template>
  <div class="search-wrapper" :class="[!grColor && 'whiteBg']">
    <img
      src="@/assets/img/search_icon.svg"
      alt="search icon"
      class="search-icon"
    />
    <input
      class="search-field"
      type="text"
      ref="searchField"
      v-model="defaultValue"
      :placeholder="placeHolder"
      @input="$emit('update:modelValue', defaultValue)"
    />
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  data() {
    return {
      defaultValue: this.modelValue,
    };
  },
  mounted() {
    if (this.focus) {
      this.$refs.searchField.focus();
    }
  },
  props: {
    modelValue: { type: [Number, String], default: null },
    placeHolder: { type: [Number, String], default: null },
    grColor: { type: [Boolean], default: true },
    focus: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.search-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f5f2eb;
  border-radius: 25px !important;
  height: 44px;
}

.search-field {
  margin-left: 6px;
  font-size: 16px !important;
  font-weight: 600;
  background-color: transparent;
  border: none !important;
  box-shadow: none !important;
}

.search-field::placeholder {
  font-weight: 600 !important;
  color: black;
}
.search-icon {
  margin-left: 20px;
  width: 20px;
  height: 20px;
}
.search-field:focus {
  outline: none;
  outline-color: transparent;
  outline-style: none;
  outline-width: 0;
  font-size: 16px !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: none;
}
.whiteBg {
  background-color: white;
}

@media screen and (min-width: 768px) {
  .search-wrapper {
    height: 42px;
  }
}
</style>
