<template>
  <div class="row flex-row justify-content-center gap-2 mb-4">
    <div class="col-12 col-md-10 col-lg-8">
      <div
        :class="titleClass"
        class="subheading-tiny align-items-center d-flex"
      >
        {{ title }}
      </div>
      <div
        v-if="paragraph"
        class="subheading-tiny form-paragraph align-items-center d-flex mt-3"
      >
        {{ paragraph }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    titleClass: { type: String, default: "" },

    paragraph: { type: String, default: null },
  },
};
</script>

<style lang="scss" scoped>
.form-label {
  font-size: 13px;
  text-wrap: nowrap;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.52px;
  &:not(.form-paragraph) {
    margin-bottom: 0px;
  }
}
.form-paragraph {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.52px;
}
</style>
