<template>
  <div class="sidebar-wrapper" @click="!isLoading && closeMenu()">
    <div class="main-layout" @click.stop>
      <LoadingOverlay v-if="isLoading" />
      <div
        class="sidebar-container container-fluid m-0 position-relative"
        :class="isExpanded ? 'expanded' : 'collapsed'"
      >
        <div v-if="showContent" class="mb-4">
          <template v-for="row in sideBar.top" :key="row.updated">
            <SideBarRow
              :label="$t(row.label)"
              v-if="row.requires === null || rowMeetsRequirements(row.requires)"
            >
              <template #sbrAction>
                <GrDropdown
                  :key="currentLanguage"
                  sidebar
                  v-if="row.action.options && row.action.options?.length"
                  :options="
                    row.action.reduced === 'iso'
                      ? row.action.options.map((option) => {
                          return { iso: option.iso, label: $t(option.label) };
                        })
                      : row.action.options
                  "
                  :label="row.action.dropdownLabel"
                  :reduce="row.action.reduced"
                  :placeholder="
                    row.action.placeholder && $t(row.action.placeholder)
                  "
                  v-model="row.action.value"
                  @update:modelValue="(value) => row.action.updated(value)"
                />
                <div v-else-if="row.fallbackValue">
                  {{ $t(row.fallbackValue) }}
                </div>
              </template>
            </SideBarRow>
          </template>
        </div>
        <div v-if="showContent">
          <template v-for="row in sideBar.mid" :key="row.label">
            <SideBarRow
              v-if="row.requires === null || rowMeetsRequirements(row.requires)"
              :label="$t(row.label)"
              @clicked="
                (value) =>
                  value ? row.navigate(value) : row.navigate(row.route)
              "
              :subNavigations="
                row.subNavigations &&
                row.subNavigations.map((nav) => {
                  return { label: $t(nav.label), route: nav.route };
                })
              "
            />
          </template>
        </div>
        <div class="mt-4" v-if="showContent">
          <SideBarRow
            v-if="rowMeetsRequirements(sideBar.bottom.requires)"
            :label="$t(sideBar.bottom.signOut)"
            @clicked="sideBar.bottom.logOut()"
          />
          <SideBarRow
            v-else
            :label="$t(sideBar.bottom.signIn)"
            @clicked="sideBar.bottom.logIn()"
          />
        </div>
        <img
          v-if="showContent && companyLogo"
          alt="company-logo"
          :src="companyLogo"
          class="sidebar-logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GrDropdown from "@/shared/components/GrDropdown.vue";
import SideBarRow from "@/shared/navbar/SideBarRow.vue";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";

export default {
  emits: ["update:modelValue"],
  props: {
    sideBar: { type: Object, default: null },
    isLoading: { type: Boolean, defualt: false },
    companyLogo: { type: String, default: null },
  },
  data() {
    return {
      isExpanded: false,
      showContent: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.authenticated;
    },
    userHasProjects() {
      return this.$store.getters["auth/userProjects"];
    },
    currentLanguage() {
      return this.$store.getters.language;
    },
  },
  methods: {
    rowMeetsRequirements(requirements) {
      let requirementsAreValid = true;
      requirements.forEach((req) => {
        if (req === "A" && !this.isAuthenticated) {
          requirementsAreValid = false;
        }

        if (req === "P" && !this.userHasProjects) {
          requirementsAreValid = false;
        }
      });
      return requirementsAreValid;
    },

    closeMenu() {
      this.showContent = false;
      this.isExpanded = false;
      window.setTimeout(() => {
        this.$emit("update:modelValue", false);
      }, 200);
    },
    openMenu() {
      window.setTimeout(() => {
        this.isExpanded = true;
      }, 200);
      window.setTimeout(async () => {
        window.setTimeout(() => {
          this.isExpanded = true;
        }, 200);
        window.setTimeout(() => {
          this.showContent = true;
        }, 400);
      }, 400);
    },
  },
  mounted() {
    this.openMenu();
  },
  components: {
    GrDropdown,
    SideBarRow,
    LoadingOverlay,
  },
};
</script>

<style scoped>
@import "../../assets/scss/navbar.scss";
</style>
