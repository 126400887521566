<template>
  <div
    class="group-container"
    :class="[stickyTop && 'sticky', noMargin && 'no-margin', noGap && 'no-gap']"
  >
    <div class="overlay" v-if="disabled"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    stickyTop: { type: Boolean, default: false },
    noMargin: { type: Boolean, default: false },
    noGap: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.group-container > *:last-child {
  margin-bottom: 0px;
}
</style>

<style lang="scss" scoped>
.group-container {
  width: 100%;
  background-color: #f7f5ee;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.no-margin {
  margin-bottom: 0 !important;
}

.no-gap {
  gap: 0 !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#f5f2eb, 0.7); /* Adjust opacity as needed */
  z-index: 25; /* Adjust the z-index to make sure it's on top of other content */
  /* Add other styles as needed */
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  top: 96px;
  z-index: 10;
}
</style>
