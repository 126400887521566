<template>
  <form @submit.prevent="resetPassword()">
    <VerificationModal
      :title="$t('verificationTitles.resetPassword')"
      :confirm-button-text="
        resetError || resetSuccess ? $t(`button.close`) : $t('button.send')
      "
      :close-on-confirm="resetError || resetSuccess"
      :single-button="resetError || resetSuccess"
      :confirmButtonType="!resetError && !resetSuccess ? 'submit' : 'button'"
      @confirm="resetError || resetSuccess ? $emit('close') : ''"
      @close="$emit('close')"
    >
      <template #text>
        <InputField
          v-if="!resetSuccess && !resetError"
          :label="$t('placeholders.enterYourEmail')"
          type="email"
          v-model="email"
          :hasError="showWarning"
          :errorText="$t('errorMessage.incorrectEmail')"
          :isRequired="true"
        />
        <p v-if="resetSuccess">
          {{ $t("verificationTexts.passwordResetSuccess", [email]) }}
        </p>
        <p v-if="resetError">
          {{ $t("verificationTexts.passwordResetError") }}
        </p>
      </template>
    </VerificationModal>
  </form>
</template>

<script>
import InputField from "@/shared/components/InputField.vue";
import VerificationModal from "@/shared/modals/VerificationModal.vue";
export default {
  emits: ["close"],
  props: {
    resetPasswordApi: { type: Function, default: () => {} },
  },
  data() {
    return {
      email: "",
      resetSuccess: false,
      resetError: false,
      showWarning: false,
    };
  },
  components: { InputField, VerificationModal },
  methods: {
    resetPassword() {
      if (this.email.length) {
        this.resetPasswordApi(this.email)
          .then(() => {
            this.resetSuccess = true;
          })
          .catch((error) => {
            this.resetError = true;
            this.$httpError(error, "ResetPasswordModal - resetPassword");
          });
      } else {
        this.showWarning = true;
      }
    },
  },
};
</script>
