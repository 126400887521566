<template>
  <div class="position-relative" ref="vueSelect">
    <label v-if="textLabel" class="mb-1 text-small">{{ textLabel }}</label>
    <vSelect
      ref="grDropdown"
      :class="[
        room && 'room-dropdown',
        small && 'small-dropdown',
        size,
        showError && 'warning',
        sidebar && 'sidebar-dropdown',
      ]"
      :filterBy="filterBy"
      :options="
        room
          ? options.map((option, i) => {
              option.index = i;
              return option;
            })
          : options
      "
      v-model="localValue"
      :clearable="clearable"
      :reduce="(option) => (reduce ? option[reduce] : option)"
      :label="label"
      :placeholder="showError ? null : placeholder"
      @option:selecting="
        (option) => {
          $emit('update:modelValue', reduce ? option[reduce] : option);
        }
      "
      @open="scrollBackOnClose && calculatePosition()"
      @close="scrollBackOnClose && scrollToPosition()"
    >
      <template #list-header>
        <slot name="list-header" />
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <img src="@/assets/img/drop-arrow.svg" />
        </span>
      </template>
      <template #option="option">
        <slot name="option" :option="option" />
      </template>
      <template #list-footer>
        <slot name="list-footer" />
      </template>
    </vSelect>
    <div class="error-wrapper" v-if="showError">
      <p class="text-tiny">{{ errorText }}</p>
    </div>
    <div
      v-if="position !== null"
      class="return-arrow"
      @click="
        $refs.vueSelect.blur();
        scrollToPosition();
      "
    >
      <img src="@/assets/img/arrow_left.svg" alt="return arrow" />
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  emits: ["update:modelValue"],
  data() {
    return {
      localValue: this.modelValue ?? this.value,
      position: null,
    };
  },
  computed: {
    desktopSize() {
      return screen.width > 1100;
    },
  },
  methods: {
    calculatePosition() {
      if (!this.desktopSize) {
        this.position =
          document.documentElement.scrollTop ||
          document.body.parentElement.scrollTop;
      }
    },
    scrollToPosition() {
      if (!this.desktopSize) {
        window.parent.scrollTo({
          top: this.position,
          behavior: "instant",
        });
        this.position = null;
      }
    },
  },

  props: {
    small: { type: Boolean, default: false },
    filterBy: {
      type: Function,
      default(option, label, search) {
        return (
          (label || "")
            .toLocaleLowerCase()
            .indexOf(search.toLocaleLowerCase()) > -1
        );
      },
    },
    label: { type: String, default: null },
    placeholder: { type: String, default: null },
    modelValue: { type: [Number, String, Object], default: null },
    value: { type: [Number, String, Object], default: null },
    options: { type: [Object, Array], default: null },
    clearable: { type: Boolean, default: false },
    reduce: { type: String, default: null },
    textLabel: { type: String, default: null },
    size: { type: String, default: null },
    showError: { type: Boolean, default: false },
    errorText: { type: String, default: "Error" },
    scrollBackOnClose: { type: Boolean, default: true },
    room: { type: Boolean, default: false },
    sidebar: { type: Boolean, default: false },
  },
  components: { vSelect },
};
</script>

<style scoped>
@import "@/assets/scss/select.scss";
@import "vue-select/dist/vue-select.css";

.error-wrapper {
  position: absolute;
  right: 48px;
  top: 26%;
  color: #dc3545;
  font-weight: 40;
  background-color: white;
  z-index: 4;
  font-size: 13px;
}

.warning-border {
  border: 1.5px solid #dc3545 !important;
}

.full {
  width: 100%;
}

.return-arrow {
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 40;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
