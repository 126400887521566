import axios from "axios";
import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/item/router";
import store from "./store";
import { createI18n } from "vue-i18n";
import Vue3TouchEvents from "vue3-touch-events";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/style.scss";
import "@/assets/scss/typography.scss";
import "bootstrap/dist/js/bootstrap.js";
import HttpErrorPlugin from "./httpErrorPlugin";

axios.defaults.baseURL = process.env.VUE_APP_LARAVEL_API;

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

const app = createApp(App);
app.use(store);
app.use(router);
const getTranslations = async () => {
  const response = await axios.get(
    `https://app-ext-api.inputinterior.se/api/get-translations/ITEM`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const fallbackLocale =
    navigator.language.slice(0, 2) ?? process.env.VUE_APP_I18N_FALLBACK_LOCALE;

  const i18n = createI18n({
    fallbackLocale: fallbackLocale || "en",
    locale: store.getters.language || fallbackLocale || "en",
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    messages: {
      sv: response.data.sv,
      en: response.data.en,
      da: response.data.da,
      no: response.data.no,
      fi: response.data.fi,
    },
  });
  app.use(i18n);
  app.use(HttpErrorPlugin, { i18n, store });
  app.use(Vue3TouchEvents);
  app.mount("#app");
};
getTranslations();
