<template>
  <LoadingOverlay v-if="isLoading" />

  <GreenifiedCard :grColor="false" v-if="!isLoading">
    <template #cardContent>
      <HandleViewHeader
        :text="
          addressToEdit
            ? `${$t('pageHeaders.editAddress')} - ${building}`
            : $t('pageHeaders.createNewAddress')
        "
        @return="$emit('close')"
      />
      <HandleViewRow :topMargin="5">
        <InputField
          v-if="!addressToEdit"
          :placeHolder="$t('placeholders.buildingName')"
          v-model="building"
          :showError="!building && showError"
          :errorText="$t('errorMessage.missingBuildingName')"
        />
      </HandleViewRow>
      <HandleViewRow>
        <InputField
          :placeHolder="$t('placeholders.address')"
          v-model="address.adress"
          :showError="!address.adress && showError"
          :errorText="$t('errorMessage.missingAddress')"
        />
      </HandleViewRow>
      <HandleViewRow>
        <InputField
          :placeHolder="$t('placeholders.postCode')"
          :showError="!address.postnummer && showError"
          v-model="address.postnummer"
          @update:modelValue="
            (value) => (address.postnummer = value.toString())
          "
          type="number"
          :errorText="$t('errorMessage.missingPostalCode')"
        />
      </HandleViewRow>
      <HandleViewRow>
        <InputField
          :placeHolder="$t('placeholders.city')"
          v-model="address.ort"
          :showError="!address.ort && showError"
          :errorText="$t('errorMessage.missingCity')"
        />
      </HandleViewRow>
      <HandleViewRow>
        <GrDropdown
          :placeholder="$t('placeholders.country')"
          :showError="!address.land && showError"
          :errorText="$t('errorMessage.missingCountry')"
          v-model="address.land"
          :options="countries"
          label="label"
          reduce="country"
        />
      </HandleViewRow>
      <HandleViewFooter
        :confirmText="
          addressToEdit ? $t('button.saveAddress') : $t('button.createAddress')
        "
        :cancelText="$t('button.remove')"
        @cancel="addressToEdit ? (verifyDelete = true) : $emit('close')"
        @confirm="
          () => {
            if (
              !address.adress ||
              !address.land ||
              !address.postnummer ||
              !address.ort ||
              !building
            ) {
              showError = true;
            } else {
              addressToEdit ? updateAddress() : postAddress();
              showError = false;
            }
          }
        "
      />
    </template>
  </GreenifiedCard>
  <VerificationModal
    v-if="verifyDelete"
    @close="verifyDelete = false"
    @confirm="deleteAddress()"
    :title="$t('verificationTitles.removeAddress')"
    :text="$t('verificationTexts.verifyRemoveAddress', [building])"
  />
</template>

<script>
import item from "@/item/services/api/item";
import GreenifiedCard from "@/shared/components/GreenifiedCard.vue";
import InputField from "@/shared/components/InputField.vue";
import GrDropdown from "@/shared/components/GrDropdown.vue";
import VerificationModal from "@/shared/modals/VerificationModal.vue";
import LoadingOverlay from "@/shared/components/LoadingOverlay.vue";
import HandleViewHeader from "./HandleViewHeader.vue";
import HandleViewFooter from "./HandleViewFooter.vue";
import HandleViewRow from "./HandleViewRow.vue";

export default {
  data() {
    return {
      projectNumber: this.$store.getters.project_settings.projekt,
      showError: false,
      isLoading: true,
      verifyDelete: false,
      newAddress: {
        adress: null,
        land: null,
        postnummer: null,
        ort: null,
      },
      address: null,
      building: null,
      countries: [
        { label: this.$t("countries.sweden"), country: "SE" },
        { label: this.$t("countries.denmark"), country: "DK" },
        { label: this.$t("countries.norway"), country: "NO" },
        { label: this.$t("countries.finland"), country: "FI" },
      ],
    };
  },
  props: {
    addressToEdit: { type: Object, default: null },
  },
  emits: ["close", "update"],
  components: {
    GreenifiedCard,
    InputField,
    GrDropdown,
    VerificationModal,
    LoadingOverlay,
    HandleViewHeader,
    HandleViewFooter,
    HandleViewRow,
  },
  methods: {
    deleteAddress() {
      item
        .deleteAddress(this.projectNumber, this.addressToEdit.id)
        .then(() => {
          this.$emit("update");
        })
        .catch((e) => {
          this.$httpError(e, "EditAddress - deleteAddress");
        });
    },
    updateAddress() {
      item
        .updateAddress(
          this.projectNumber,
          {
            building: this.building,
            address: this.address,
          },
          this.addressToEdit.id
        )
        .then(() => {
          this.loadingPost = false;
          this.$emit("update");
        })
        .catch((e) => {
          this.$httpError(e, "EditAddress - updateAddress");
        });
    },
    postAddress() {
      item
        .postAddress(this.projectNumber, {
          building: this.building,
          address: this.address,
        })
        .then(() => {
          this.loadingPost = false;
          this.$emit("update");
        })
        .catch((e) => {
          this.$httpError(e, "EditAddress - postAddress");
        });
    },
  },
  mounted() {
    this.address = this.addressToEdit
      ? {
          adress: this.addressToEdit.address.adress,
          land: this.addressToEdit.address.land,
          postnummer: this.addressToEdit.address.postnummer,
          ort: this.addressToEdit.address.ort,
        }
      : this.newAddress;

    this.building = this.addressToEdit ? this.addressToEdit.building : null;
    this.isLoading = false;
  },
};
</script>

<style scoped>
.return-icon-wrapper {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
}

.return-icon {
  transform: rotate(90deg);
}
</style>
