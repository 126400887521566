<template>
  <div class="desktop-navigation-wrapper row" :key="currentLanguage">
    <div class="col-6 d-flex">
      <template v-for="(link, i) in desktop.left" :key="link.label">
        <div
          v-if="link.requires === null || rowMeetsRequirements(link.requires)"
          :class="[
            $route.name === link.route
              ? 'fw-bolder cursor-default'
              : 'fw-normal navigation-link',
            i !== desktop.left.length - 1 && 'me-5',
          ]"
        >
          <p
            v-if="!link.dropdown"
            class="m-0 h-100"
            @click="
              link.navigate(link.route);
              if ($refs.linkDropdown?.[0].selected) {
                $refs.linkDropdown[0].selected = null;
              }
            "
          >
            {{ $t(link.label) }}
          </p>
          <StandardDropdown
            v-else
            bgColor="white"
            ref="linkDropdown"
            :options="link.dropdown.options"
            :i18n="true"
            :defaultValue="link.dropdown.value"
            :label="link.dropdown.dropdownLabel"
            :placeholder="$t(link.dropdown.placeholder)"
            :selectedInBold="true"
            @update:modelValue="(route) => link.navigate(route)"
            :reduce="link.dropdown.reduced"
          />
        </div>
      </template>
    </div>
    <div class="col-6 d-flex align-items-center justify-content-end">
      <img
        v-if="companyLogo"
        alt="company-logo"
        :src="companyLogo"
        class="company-logo border-right pe-4 me-4"
      />
      <div class="me-5" v-for="select in desktop.right" :key="select.label">
        <StandardDropdown
          :key="isAuthenticated"
          v-if="
            select.dropdown.options?.length &&
            (select.requires === null || rowMeetsRequirements(select.requires))
          "
          :options="select.dropdown.options"
          :defaultValue="select.dropdown.value"
          bgColor="white"
          :label="select.dropdown.dropdownLabel"
          :reduce="select.dropdown.reduced"
          :selectedInBold="true"
          :i18n="select.dropdown.reduced === 'iso'"
          :placeholder="
            select.dropdown.placeholder && $t(select.dropdown.placeholder)
          "
          v-model="select.dropdown.value"
          @update:modelValue="
            (value) => {
              select.dropdown.updated(value);
            }
          "
        />
      </div>

      <div :class="'fw-normal navigation-link'">
        <p
          class="m-0 h-100"
          @click="desktop.end.logOut()"
          v-if="rowMeetsRequirements(desktop.end.requires)"
        >
          {{ $t(desktop.end.signOut) }}
        </p>
        <p v-else class="m-0 h-100" @click="desktop.end.logIn()">
          {{ $t(desktop.end.signIn) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import StandardDropdown from "@/shared/components/StandardDropdown.vue";

export default {
  props: {
    companyLogo: { type: String, default: null },
    desktop: { type: Object, default: null },
  },
  data() {
    return {};
  },
  computed: {
    userHasProjects() {
      return this.$store.getters["auth/userProjects"];
    },

    isAuthenticated() {
      return this.$store.state.auth.authenticated;
    },

    currentLanguage() {
      return this.$store.getters.language;
    },
  },
  methods: {
    rowMeetsRequirements(requirements) {
      let requirementsAreValid = true;
      requirements.forEach((req) => {
        if (req === "A" && !this.isAuthenticated) {
          requirementsAreValid = false;
        }

        if (req === "P" && !this.userHasProjects) {
          requirementsAreValid = false;
        }
      });
      return requirementsAreValid;
    },
  },

  components: { StandardDropdown },
};
</script>

<style scoped>
@import "../../assets/scss/navbar.scss";
</style>
