import item_api from "@/item/services/item-api";
import supplier_api from "@/item/services/supplier-api";

import api from "@/item/services/api";

import image_api from "@/item/services/image-api";

import project_api from "@/item/services/project-api";
import greenified from "@/item/services/greenified";

export default {
  verifyItemId(id) {
    return item_api().get(`${id}/verify`);
  },
  getProjects() {
    return api().get("/projects");
  },
  getProject(projectNr) {
    return api().get("/project/" + projectNr);
  },

  getItem(id) {
    return item_api().get(id);
  },
  getPublicItem(id) {
    return item_api().get(`${id}/public`);
  },
  getProjectItems(project, search, offset, isoLang) {
    return project_api().get(
      `/${project}/item-history?search=${search}&offset=${offset}&isoLang=${isoLang}`
    );
  },
  postItem(id, item) {
    return item_api().put(id, item);
  },
  getProjectSettings(project) {
    return project_api().get(project);
  },
  getItemDescStructure() {
    return greenified().get("/acf-schema/product");
  },
  postImage(formdata) {
    return image_api().post("", formdata);
  },
  getSuppliers(search) {
    return api().get("supplier?search=" + search);
  },
  addSupplier(levkod, projectnr) {
    return supplier_api().post(
      "project",
      JSON.stringify({ levkod, projectnr })
    );
  },
  /**
   * gets the latest Greenified structure
   * @param {*} projectNr
   * @returns
   */
  getGreenifiedStructure(projectNr) {
    return api().get(`/project/${projectNr}/greenified/structure`);
  },

  getRooms(projectNr) {
    return api().get("/project/" + projectNr + "/room");
  },

  postRooms(projectNr, data) {
    return api().post("/project/" + projectNr + "/room", JSON.stringify(data));
  },

  resetPassword(email) {
    return api().post(`/password/reset/` + email);
  },

  getAddresses(projectNumber, ids = null) {
    if (!ids) {
      return api().get(`/project/${projectNumber}/address`);
    }
    return api().get(
      `/project/${projectNumber}/address?${encodeURI(
        "ids[]=" + ids.join("&ids[]=")
      )}`
    );
  },

  deleteAddress(projectNumber, id) {
    return api().delete(`/project/${projectNumber}/address/${id}`);
  },

  postAddress(projectNumber, address) {
    return api().post(
      `/project/${projectNumber}/address`,
      JSON.stringify(address)
    );
  },
  updateAddress(projectNumber, address, id) {
    return api().post(
      `/project/${projectNumber}/address/${id}`,
      JSON.stringify(address)
    );
  },

  getQuantity(projectNr, guestToken = null) {
    if (guestToken) {
      return api().get(`/guest/${guestToken}/quantities`);
    } else {
      return api().get(`/project/${projectNr}/quantities`);
    }
  },
  postLabelOrder(projectNr, order) {
    return api().post(
      "/project/" + projectNr + "/label-order",
      JSON.stringify(order)
    );
  },

  getCompId(projectNr, id) {
    return project_api().get(`/${projectNr}/product/${id}/validate`);
  },
};
