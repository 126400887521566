<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :id="bgColor"
      :class="{ open: open }"
      @click="open = !open"
    >
      <p v-if="selected && label">
        {{ i18n ? $t(selected[label]) : selected[label] }}
      </p>
      <p v-else>{{ selected ?? placeholder }}</p>
      <img
        src="@/assets/img/drop-arrow.svg"
        alt="dropdown-arrow"
        :class="open ? 'rotate-arrow' : 'dropdown-arrow'"
      />
    </div>
    <div
      class="items"
      :style="{ backgroundColor: bgColor }"
      :class="{ selectHide: !open }"
    >
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('update:modelValue', reduce ? option[reduce] : option);
        "
      >
        <p
          v-if="label"
          :class="
            selectedInBold && selected?.[label] === option[label] && 'emphasis'
          "
        >
          {{ i18n ? $t(option[label]) : option[label] }}
        </p>
        <p v-else :class="selectedInBold && selected === option && 'emphasis'">
          {{ option }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    defaultValue: {
      type: [Number, String],
      default: null,
    },
    bgColor: {
      type: String,
      default: "#333",
    },
    reduce: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    i18n: {
      default: false,
      type: Boolean,
    },

    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    selectedInBold: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      selected:
        this.reduce && this.defaultValue
          ? this.options.find(
              (option) => option[this.reduce] === this.defaultValue
            )
          : this.defaultValue
          ? this.defaultValue
          : this.reduce && this.modelValue
          ? this.options.find(
              (option) => option[this.reduce] === this.modelValue
            )
          : this.modelValue
          ? this.modelValue
          : null,
      open: false,
    };
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
  white-space: nowrap;
  min-width: max-content;
}

.custom-select .selected {
  border-radius: 6px;
  padding-left: 0.3rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.selected p {
  min-width: 1rem;
  max-width: 6rem;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  margin: 0 !important;
}

.dropdown-arrow {
  margin-left: 0.5rem;
  width: 1rem;
  transition: 0.2s;
}

.rotate-arrow {
  margin-left: 0.5rem;
  width: 1rem;
  transform: rotate(180deg);
  transition: 0.2s;
}

.custom-select .selected.open {
  border-radius: 6px 6px 0px 0px;
}

#white:after {
  border-color: #333 transparent transparent transparent !important;
}

#black:after {
  border-color: white transparent transparent transparent !important;
}

.custom-select .items {
  margin-top: 2.2rem;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #f5f2eb;
  border-top: 0;
  overflow: hidden;
  position: absolute;
  min-width: 3.4rem;
  left: 0;
  z-index: 10;
  min-width: 100%;
}

.custom-select .items div {
  padding: 0.4rem;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  text-decoration: underline;
  color: black;
}

.selectHide {
  display: none;
}
</style>
